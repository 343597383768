import React, { useEffect, useState } from 'react'
import './space-range.less'
import { useParams } from 'react-router-dom'
import { getGroupsByBuildingUuid } from '@/api/spec-common/group';
import { Group } from '@/commons/interface/speccommon/group';
import classNames from 'classnames';

export default function SpaceRangePanel() {
    const { projectUuid, buildingUuid } = useParams();

    const [groups, setGroups] = useState<Group[]>([])

    const [groupsR1, setGroupsR1] = useState<Group[]>([]);
    const [groupsRx, setGroupsRx] = useState<Group[]>([]);

    const [currentSpaceR1, setCurrentSpaceR1] = useState<string>();

    const [currentSpaceRx, setCurrentSpaceRx] = useState<string>();

    useEffect(() => {
        if (buildingUuid == null) return;

        getGroupsByBuildingUuid(projectUuid, buildingUuid).then(res => {
            let g: Group[] = res.data || [];
            setGroups(g);
            setGroupsR1(g.filter(item => !item?.parentUuid) || []);
        }).catch(err => {
            let g: Group[] = [
                { uuid: '0', name: '整楼', level: 1 },
                { uuid: '1', name: '1F', level: 1 },
                { uuid: '2', name: '2F', level: 1 },
                { uuid: '3', name: '2F(夹)', level: 1 },
                { uuid: '4', name: '3F', level: 1 },
                { uuid: '5', name: '4F', level: 1 },
                { uuid: '6', name: '5F', level: 1 },
                { uuid: '7', name: '贵宾候车厅', level: 2, parentUuid: '1' },
                { uuid: '8', name: '软席候车厅', level: 2, parentUuid: '1' },
                { uuid: '9', name: '卫生间a', level: 3, parentUuid: '7' },
                { uuid: '10', name: '卫生间B', level: 3, parentUuid: '7' },
            ];
            setGroups(g);
            setGroupsR1(g.filter(item => item.level === 1) || [])

        })
    }, [buildingUuid])

    useEffect(() => {
        if (currentSpaceR1 == null || currentSpaceR1.length == 0) {
            setGroupsRx([]);
            return;
        }
        let g = groups.filter(item => item.parentUuid === currentSpaceR1) || [];
        g.forEach(item => {
            let childs = groups.filter(child => child.parentUuid == item.uuid) || [];
            item.children = childs;
        })
        setGroupsRx(g)
    }, [currentSpaceR1])

    return (
        <div className='space-range-container'>
            <div className='space-r1-wrap'>
                {groupsR1.map((group, index) => (
                    <div key={index} className={classNames({
                        'space-r1-item': true,
                        'active': currentSpaceR1 === group.uuid
                    })} onClick={() => {
                        setCurrentSpaceR1(group.uuid)
                    }}>{group.name}</div>
                ))}
            </div>
            <div className='space-rx-wrap'>
                {groupsRx.map((group, index) => (
                    <div key={index}>
                        <div className={classNames({
                            'space-rx-item': true,
                            'active': currentSpaceRx == group.uuid
                        })} onClick={() => {
                            setCurrentSpaceRx(group.uuid)
                        }}>
                            {group.name}
                        </div>
                        {group.children?.map(child => (
                            <div key={child?.id} className={classNames({
                                'space-rx-item child': true,
                                'active': currentSpaceRx == child.uuid
                            })} onClick={() => {
                                setCurrentSpaceRx(child.uuid)
                            }}>{child.name}</div>
                        ))}
                    </div>

                ))}
            </div>
        </div>
    )
}
