import './index.less'
import { Navigate, Route, Routes, useParams } from 'react-router-dom'
import { graphicStore } from '@/commons/store/graphic-store';
import { TnEngineContext, TnEngineExtraContext, AxisT, ttf, font, ModelBase, Line, RenderMode } from 'pytha';
import { BimBaseContext, ModelsInBimCommon, ModelsInBimStructure, ModelsInBuilding, ModelsInFire } from 'tnbimbase';
import { AmbientLight, Color, DirectionalLight, Mesh, MeshBasicMaterial, PointLight, Scene, SphereGeometry, Vector3 } from 'three';
import genUUID from '@/commons/utils/gen-uuid';
import { useState, useContext, useRef, useEffect } from 'react';
import localStore from '@/commons/store/local-store';
import axios from 'axios';
import { findGlobalUcs, findUcsInProject } from '@/api/geometry/ucs';
import { Ucs } from '@/commons/interface/geometry/ucs';
import { findAllLayersInProjectUuid, findLayersInProjectUuid } from '@/api/geometry/layer';
import { Layer } from '@/commons/interface/geometry/layer';
import { findFontGlyphs, findFontTtfs, findFontsInGraphic } from '@/api/geometry/font';
import { getTextureUrl, getTextures } from '@/api/spec-common/texture';
import { Primitive } from '@/commons/interface/geometry/primitive';
import { findPrimitivesInGraphicByTypes } from '@/api/geometry/primitive';
import { ModelsInStructure } from '@/passets/structure-engine/models';
// import { ModelsInHvac } from '@/passets/hvac-engine/models';
import { Texture } from '@/commons/interface/geometry/texture';
import Viewport from './viewport';
import { SystemStatus } from '@/commons/context/system-store';
import { SystemContext } from '@/App';
import { Spin } from 'antd';
import { OnlySelectStrategy } from '@/passets/select/OnlySelectControl';
import { TINY_SELECT_STRATEGY_TYPE } from '@/passets/select/select-type';
import { MaskLoadingModal } from 'tncet-common';

export default function DrawPanel() {

    // 以下初始化引擎基本类
    if (graphicStore.context == null) {
        graphicStore.context = TnEngineContext.getInstance();
    }

    if (graphicStore.extraContext == null) {
        graphicStore.extraContext = TnEngineExtraContext.getInstance();
    }

    if (graphicStore.bimContext == null) {
        graphicStore.bimContext = BimBaseContext.getInstance();
    }

    const [finishInit, setFinishInit] = useState<boolean>(false);
    const { projectUuid } = useParams();
    const [loading, setLoading] = useState<boolean>(false);
    const [loadingTip, setLoadingTip] = useState<string>('');
    const [sceneUpdate, setSceneUpdate] = useState<string>(''); // 场景更新的信号
    const systemContext = useContext<SystemStatus>(SystemContext);
    // const tabbarContext = useContext<CommonTabbarState>(CommonTabbarContext);
    const drawRef = useRef(null);
    const isInit = useRef<boolean>(false);

    const branchUuid = null

    const hideListRef = useRef<{ [key: string]: string }>({})

    useEffect(() => {
        init();
        return (() => {
            window.removeEventListener('resize', onWindowResize);
        })
    }, [projectUuid])

    useEffect(() => {
        if (!projectUuid) {
            localStore.projectUuid = null;
            systemContext.setCurrentProjectUuid(null);
            return () => { };
        }
        if (!systemContext.activeModelUuid) {
            return () => { }
        }

        // 模型变化，更新数据
        generateData();
    }, [projectUuid, systemContext.activeModelUuid, branchUuid, systemContext.updateDrawPanel])

    // useEffect(() => {
    //     let editor = TnEngineExtraContext.getInstance().getCurrentViewEditor()
    //     if (!editor) return
    //     let entityDict = editor.entityDict
    //     if (!(Object.keys(entityDict).length > 0)) return
    //     setLoading(true)
    //     setLoadingTip("正在切换模型")
    //     for (let uuid in entityDict) {
    //         let entity = entityDict[uuid]
    //         if (systemContext.showModelUuidList.find(item => entity?.extraData?.modelUuid == item)) {
    //             entity.hidden = false
    //         } else {
    //             entity.hidden = true
    //         }
    //     }
    //     editor.listeners.signals.needRender.dispatch("redraw")
    //     setLoading(false)
    // }, [systemContext.activeModelUuid])

    const init = () => {
        localStore.projectUuid = projectUuid;
        initScene();
        document.oncontextmenu = () => {
            return false;
        }
        if (graphicStore?.context) {
            Object.keys(ModelsInBimCommon).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInBimCommon[type]);
            })
            Object.keys(ModelsInBimStructure).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInBimStructure[type]);
            })
            Object.keys(ModelsInBuilding).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInBuilding[type]);
            })
            Object.keys(ModelsInFire).forEach(type => {
                graphicStore.context.modelContext.addEntitytoCtrl(type);
                graphicStore.context.modelContext.registerModel(type, ModelsInFire[type]);
            })
        }
        window.addEventListener('resize', onWindowResize);
    }

    const onWindowResize = () => {
        graphicStore.extraContext.listeners?.signals.windowResize.dispatch();
    }

    const initScene = () => {
        return new Promise((resolve, reject) => {
            if (graphicStore.extraContext.sceneContext.scene == null) {
                let scene = new Scene();

                // 在初始化部分添加光源
                // 添加光照
                const ambientLight = new AmbientLight(0xffffff, 1);
                scene.add(ambientLight);

                const directionalLight = new DirectionalLight(0xffffff, 1);
                directionalLight.position.set(1, 0, 0).normalize();
                directionalLight.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight);

                const directionalLight1 = new DirectionalLight(0xffffff, 1);
                directionalLight1.position.set(-1, 0, 0).normalize();
                directionalLight1.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight1);
                const directionalLight2 = new DirectionalLight(0xffffff, 1);
                directionalLight2.position.set(0, 1, 0).normalize();
                directionalLight2.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight2);
                const directionalLight3 = new DirectionalLight(0xffffff, 1);
                directionalLight3.position.set(0, -1, 0).normalize();
                directionalLight3.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight3);
                const directionalLight4 = new DirectionalLight(0xffffff, 1);
                directionalLight4.position.set(0, 0, 1).normalize();
                directionalLight4.castShadow = true; // 使光源投射阴影
                scene.add(directionalLight4);

                // const directionalLight2 = new DirectionalLight(0xffffff, 0.7);
                // directionalLight2.position.set(1, 3, 1).normalize();
                // directionalLight2.castShadow = true; // 使光源投射阴影
                // scene.add(directionalLight2);
                // const sphere = new SphereGeometry(0.5, 16, 8);
                // const pointLight1 = new PointLight(0xffaa00, 4000, 1000000)
                // pointLight1.position.set(0, 0, 0)
                // pointLight1.add(new Mesh(sphere, new MeshBasicMaterial({ color: 0xff0040 })));
                // scene.add(pointLight1)

                // // 创建一个光源帮助器（可选）
                // const pointLightHelper = new PointLightHelper(pointLight1, 0.5);
                // scene.add(pointLightHelper);

                graphicStore.extraContext.sceneContext.scene = scene;

                setSceneUpdate(genUUID());
                resolve(1);
            }
        })
    }

    const generateData = () => {
        // TODO 缺少登录，暂时写死，后续添加登录后删除
        localStore.userId = genUUID();
        if (!projectUuid) return;
        setLoading(true);
        setFinishInit(false);
        // 设置世界坐标的默认颜色
        graphicStore.context.renderContext.axisColor.set(new Color(0, 0, 0))
        // 设置鼠标默认颜色
        graphicStore.context.renderContext.cusorColor.set(new Color(0, 0, 1))
        graphicStore.context.renderContext.renderType = RenderMode.CONCEPT
        localStore.projectUuid = projectUuid;
        initUcs().then((_) => {
            return initLayers();
        }).then((_) => {
            return initFonts();
        }).then((_) => {
            return initTexture();
        }).then((_) => {
            return initModels();
        }).finally(() => {
            graphicStore.extraContext.getCurrentViewEditor()?.showAllEntities(true);
            // TODO 全局设置
            graphicStore.context.renderContext.gridLineDistanceToCircle = 3000;
            graphicStore.context.renderContext.textHeight = 300;
            graphicStore.extraContext.listeners.signals.onEditStatusChanged.dispatch();
            // 关掉夹点显示
            graphicStore.extraContext.getCurrentViewEditor().clampControl.enabled = false;
            graphicStore.extraContext.getCurrentViewEditor().clampControl.hidden = true;
            // 不渲染光标
            graphicStore.extraContext.getCurrentViewEditor().cursorControl.needRenderCursor = false;
            // 重新注册选择控制器
            graphicStore.extraContext.getCurrentViewEditor().selectControl.updateStrategy(OnlySelectStrategy, TINY_SELECT_STRATEGY_TYPE.ONLY_SELECT)
            setLoading(false);
            setFinishInit(true);
        })
    }

    const initUcs = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载坐标系');
            // let axisT: AxisT = new AxisT("ucs", new Vector3(0, 0, 0), new Vector3(1, 0, 0), new Vector3(0, 1, 0), new Vector3(0, 0, 1), genUUID(), true)
            // graphicStore.context.ucsContext.setUcsList([axisT]);
            // graphicStore.context.ucsContext.setCurrentUcs(axisT)
            // resolve(1)
            setLoadingTip('正在加载坐标系');
            findUcsInProject(projectUuid, systemContext.activeModelUuid, branchUuid).then(res => {
                console.log("ucs res:", res);

                let jsonList: Ucs[] = res.data || [];
                let ucsList = [];
                jsonList.forEach((json) => {
                    if (json.isWcs) return;
                    let ucs: AxisT = new AxisT().fromJson(json);
                    ucsList.push(ucs);
                });
                // console.log('ucsList', ucsList)
                // graphicStore.context.ucsContext.setUcsList(ucsList);
                // graphicStore.context.ucsContext.setCurrentUcs(ucsList[0]);
                // resolve(1);
                findGlobalUcs(projectUuid, systemContext.activeModelUuid).then(res => {
                    let json = res.data;
                    let wcs = new AxisT().fromJson(json);
                    if (wcs != null) {
                        ucsList.push(wcs);
                    }
                    if (wcs == null && ucsList.length > 0) wcs = ucsList[0];
                    graphicStore.context.ucsContext.setUcsList(ucsList);
                    graphicStore.context.ucsContext.setCurrentUcs(wcs);
                    // if (wcs != null) {
                    //     let viewObj = wcs.render();
                    //     graphicStore.extraContext.sceneContext.scene?.add(viewObj);
                    // }
                    resolve(1);
                })
            })
        })
    }

    const initLayers = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载图层');
            // findAllLayersInProjectUuid(projectUuid).then(res => {
            findLayersInProjectUuid(projectUuid, systemContext.activeModelUuid).then(res => {
                let layers: Layer[] = res.data || [];
                hideListRef.current = {}
                layers.forEach((layer) => {
                    layer.lock = false;
                    layer.hidden = false;
                    // if (layer?.name == "轴线" || layer?.name == "0") {
                    //     hideListRef.current[lay]
                    // }
                    hideListRef.current[layer.uuid] = layer.name
                });
                let originLayer = layers.find((layer) => layer.originLayer && layer.projectUuid == projectUuid);
                if (originLayer == null) originLayer = layers[0];
                if (originLayer != null) {
                    graphicStore.context.layerContext.setCurrentLayerUuid(originLayer.uuid);
                    graphicStore.context.layerContext.setCurrentLayerColor(originLayer.color);
                    graphicStore.context.layerContext.setCurrentColor(originLayer.color);
                }
                graphicStore.context.layerContext.setLayers([...layers]);
                resolve(1);
            })
        });
    };

    const initFonts = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载文字样式');
            // 初始化时加载标注所用字体及默认字体
            if (!!graphicStore.extraContext.fontContext.state.dimFontData && !!graphicStore.extraContext.fontContext.state.currentFont) {
                resolve(1);
            }
            let ttfPromise = findFontTtfs();
            let fontPromise = findFontsInGraphic(projectUuid, systemContext.activeModelUuid, null);
            // 加载0~9和.所需要的glyphs
            let params = {
                unicodeFile: "times.ttf",
                rangeStart: 0,
                rangeEnd: 1919,
                bakFile: "stsong.ttf",
                text: '0123456789. \\:;/*=+-_abcdefghigklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ',
            };
            let glyphPromise = findFontGlyphs(params);
            axios.all([ttfPromise, fontPromise, glyphPromise]).then((res) => {
                let ttfList: ttf[] = res[0].data || [];
                graphicStore.extraContext.fontContext.generateTtfNameList(ttfList);
                let fontList: font[] = res[1].data || [];
                let defaultFont;
                fontList.forEach((font) => {
                    let ttf = ttfList.find(
                        (item) => item.name === font.fontname && item.style === font.style
                    );
                    font.ttf = ttf;
                    if (font.originFont) {
                        defaultFont = font;
                    }
                });
                graphicStore.extraContext.fontContext.setFontList(fontList);
                if (!!defaultFont) {
                    graphicStore.extraContext.fontContext.setCurrentFont(defaultFont);
                }
                graphicStore.extraContext.fontContext.setDimFontData(res[2].data);
                resolve(1);
            });
        });
    }

    const initTexture = () => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载纹理/材质');
            getTextures(projectUuid).then(res => {
                let textures: Texture[] = res.data || [];
                textures.forEach(texture => {
                    if (texture && texture.uuid && texture.url) {
                        let imageUrl = getTextureUrl(texture.url)
                        graphicStore.extraContext.materialContext.materialUrlMap[texture.uuid] = imageUrl;
                    }
                })
                resolve(1);
            })
        });
    }

    const initModels = (isNotGen?: boolean) => {
        return new Promise((resolve, reject) => {
            setLoadingTip('正在加载模型');
            if (isNotGen) {
                setLoading(true);
            }
            //将tnbimbase项目中的额外图元注册进去
            if (graphicStore.context) {
                Object.keys(ModelsInBimCommon).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInBimCommon[type]);
                })
                Object.keys(ModelsInStructure).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInStructure[type]);
                })
                Object.keys(ModelsInBimStructure).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInBimStructure[type]);
                })
                Object.keys(ModelsInBuilding).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInBuilding[type]);
                })
                Object.keys(ModelsInFire).forEach(type => {
                    graphicStore.context.modelContext.addEntitytoCtrl(type);
                    graphicStore.context.modelContext.registerModel(type, ModelsInFire[type]);
                })
            };
            let firstParam = {
                types: ['HoleT', 'DoorT', 'WindowT'],
                modelUuid: systemContext.activeModelUuid
            }
            findPrimitivesInGraphicByTypes(projectUuid, firstParam).then((res) => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    let jsonList: Primitive[] = res.data || [];
                    let entities = jsonList.map((json: Primitive) => {
                        let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.properties.type))();
                        entity.fromJson(json.properties);
                        if (
                            hideListRef.current[entity?.layerUuid] == "轴网" ||
                            hideListRef.current[entity?.layerUuid] == "轴线"
                            // || hideListRef.current[entity?.layerUuid] == "0"
                        ) {
                            return null
                        }
                        // if (!checkShowEntity(json)) {
                        //     entity.hidden = true
                        // }
                        let extraData = entity?.extraData || {}
                        extraData["modelUuid"] = json.modelUuid
                        entity.extraData = extraData
                        return entity;
                    });

                    graphicStore.extraContext.getCurrentViewEditor().clearEntityDict();
                    graphicStore.extraContext.getCurrentViewEditor().addObjects(entities, false);
                }
                isInit.current = true
                let secondParam = {
                    notTypes: ['HoleT', 'DoorT', 'WindowT'],
                    modelUuid: systemContext.activeModelUuid
                }
                return findPrimitivesInGraphicByTypes(projectUuid, secondParam)
            }).then((res) => {
                if (graphicStore.extraContext.getCurrentViewEditor()) {
                    let jsonList: Primitive[] = res.data || [];
                    let entities = jsonList.map((json: Primitive) => {
                        let entity: ModelBase = new (graphicStore.context.modelContext.getModelConstructor(json.properties.type))();
                        entity.fromJson(json.properties);
                        if (
                            hideListRef.current[entity?.layerUuid] == "轴网" ||
                            hideListRef.current[entity?.layerUuid] == "轴线"
                            // || hideListRef.current[entity?.layerUuid] == "0"
                        ) {
                            return null
                        }
                        // if (!checkShowEntity(json)) {
                        //     entity.hidden = true
                        // }
                        let extraData = entity?.extraData || {}
                        extraData["modelUuid"] = json.modelUuid
                        entity.extraData = extraData
                        return entity;
                    });
                    graphicStore.extraContext.getCurrentViewEditor().addObjects(entities, false);
                }
                resolve(1);
            }).finally(() => {
                graphicStore.extraContext.getCurrentViewEditor().listeners.signals.needRender.dispatch('redraw');
                if (isNotGen) {
                    setLoading(false);
                }
            })
        });
    }

    const checkShowEntity = (pri: Primitive) => {
        if (systemContext.showModelUuidList.find(item => pri.modelUuid == item)) {
            return true
        }
        return false
    }



    return (
        <div className='draw-panel-container'>
            <div className='viewport-item'>
                {/* todo 只能用"viewport"，pythagoras库里model都用的viewport取得element，model修改后这里可以修改viewportId */}
                <Viewport sceneUpdate={sceneUpdate} viewportId='viewport' viewHelperId='viewHelper-1' cameraType={systemContext.currentCameraType} />
            </div>
            <MaskLoadingModal
                visible={loading}
                height='70px'
                loadingTip={loadingTip}
            />
        </div>
    )
}
