import { Group } from "@/commons/interface/speccommon/group";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function getGroups(projectUuid: string, modelUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/models/${modelUuid}/groups`, {
        params: {
            ...params,
        }
    })
}

export function getGroupsByBuildingUuid(projectUuid: string, buildingUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/building/${buildingUuid}/groups`, {
        params: {
            ...params,
        }
    })
}

export function getGroupByUuids(projectUuid: string, groupUuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/groups`, groupUuids, {
        params: {
            ...params,
        }
    })
}

export function addGroup(projectUuid: string, modelUuid: string, group: Group, groupUuid: string, hasEngine: boolean, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/models/${modelUuid}/groups`, group, {
        params: {
            ...params,
            groupUuid: groupUuid,
            hasEngine: hasEngine
        }
    })
}

export function deleteGroup(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/groups/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function updateGroup(uuid: string, group: Group, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/groups/${uuid}`, group, {
        params: {
            ...params,
        }
    })
}

