import './index.less';
import { CaretDownFilled, CaretRightFilled, CaretUpFilled, CaretLeftFilled } from '@ant-design/icons';
import { ReactComponent as PickSvg } from '../icnos/pick.svg'
import { ReactComponent as RotateSvg } from '../icnos/rotate.svg'
import { ReactComponent as WorldSvg } from '../icnos/world.svg'
import { useParams } from 'react-router-dom';

import { useEffect, useRef, useState } from 'react';
import { graphicStore } from '@/commons/store/graphic-store';
import { Camera, Clock, OrthographicCamera, PerspectiveCamera, WebGLRenderer } from 'three';
import { DefaultMouseControl, ViewCube, ViewEditor } from 'pytha';
import classNames from 'classnames';


const _clock = new Clock();


export enum CAMERA_TYPE {
    Orthographic,
    Perspective
}

interface Iprops {
    sceneUpdate: string,
    viewportId: string,
    viewHelperId: string,
    cameraType?: number,  //默认为正交相机
}

export default function Viewport(props: Iprops) {
    const { projectUuid } = useParams();
    const viewportId = props.viewportId;
    const viewHelperId = props.viewHelperId;
    const viewCube = useRef<ViewCube>();
    const stats = useRef<Stats>();
    const [activeTool, setActiveTool] = useState<number>(0)

    // const onSelect= (entities: ModelBase) => {
    //     console.log("entities", entities);
    // }

    useEffect(() => {
        if (projectUuid == null || graphicStore.extraContext == null || !graphicStore.extraContext.sceneContext.scene) {            
            return () => { }
        };
        let editor = new ViewEditor(graphicStore.extraContext.listeners, projectUuid, viewportId);
        graphicStore.extraContext.addViewEditor(viewportId, editor);
        // editor.listeners.signals.onSelectChanged.add(onSelect);

        const renderer = createRenderer();
        editor.renderer = renderer;

        const camera = createCamera(graphicStore.context.renderContext.cameraEdge, props.cameraType ? props.cameraType : CAMERA_TYPE.Orthographic);
        editor.camera = camera;

        const viewControl = initControls(editor, renderer, camera);

        viewCube.current = createViewCube(camera, viewControl);
        editor.viewCube = viewCube.current;

        graphicStore.extraContext.setCurrentViewportId(viewportId);

        render();

        return (() => {
            if (!!editor) {
                if (!!viewCube?.current) {
                    viewCube?.current.dispose();
                }
                // editor.listeners.signals.onSelectChanged.remove(onSelect);
                editor.renderer.domElement.parentElement.removeChild(editor.renderer.domElement);
                editor.renderer.setAnimationLoop(null);
                editor.renderer.forceContextLoss()
                editor.dispose();
                if (graphicStore.extraContext && graphicStore.extraContext.sceneContext
                    && graphicStore.extraContext.sceneContext.scene) {
                    graphicStore.extraContext.sceneContext.scene.clear();
                    graphicStore.extraContext.sceneContext.scene = null;
                }
            }
            graphicStore.extraContext?.deleteViewEditor(viewportId);
        })

    }, [projectUuid, graphicStore.extraContext, props.sceneUpdate])

    useEffect(() => {
        const editor = graphicStore.extraContext.getViewEditor(viewportId);
        if (!editor) return;
        switch (props.cameraType) {
            case CAMERA_TYPE.Orthographic:
                editor.changeCameraType("OrthographicCamera");
                viewCube.current.camera = editor.camera;
                graphicStore.extraContext.getCurrentViewEditor()?.showAllEntities(true);
                graphicStore.extraContext.listeners.signals.needRender.dispatch();
                break;
            case CAMERA_TYPE.Perspective:
                editor.changeCameraType("PerspectiveCamera");
                viewCube.current.camera = editor.camera;
                // 关掉夹点显示
                editor.clampControl.enabled = false;
                editor.clampControl.hidden = true;
                // 不渲染光标
                editor.cursorControl.needRenderCursor = false;
                //不显示旋转球
                editor.viewControl.needRotateHelper = false;
                // 钝化选择
                editor.selectControl.setEnabled(false);
                // 删除坐标系
                let oldUcs = graphicStore.context.ucsContext.currentUcs;
                if (!!oldUcs && oldUcs.viewObj && oldUcs.viewObj.parent) {
                    // 遍历 viewObj 及其子对象，设置透明度
                    oldUcs.viewObj.parent.remove(oldUcs.viewObj);
                }
                graphicStore.extraContext.getCurrentViewEditor()?.showAllEntities(true);
                graphicStore.extraContext.listeners.signals.needRender.dispatch();
                break;
        }
    }, [props.cameraType])

    useEffect(() => {
        if (!graphicStore.extraContext) return () => { };
        graphicStore.extraContext.listeners.signals.windowResize.add(onWindowResize);
        graphicStore.extraContext.listeners.signals.needRender.add(render);
        graphicStore.extraContext.listeners.signals.onViewCubeWorking.add(onViewCubeWorking);
        graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.add(reSetToolBar);
        return (() => {
            if (!graphicStore.extraContext) return;
            graphicStore.extraContext.listeners.signals.windowResize.remove(onWindowResize);
            graphicStore.extraContext.listeners.signals.needRender.remove(render);
            graphicStore.extraContext.listeners.signals.onViewCubeWorking.remove(onViewCubeWorking);
            graphicStore.extraContext.listeners.signals.onOpeCommandControlEnd.remove(reSetToolBar);
        })
    }, [projectUuid, graphicStore.extraContext, viewCube])

    useEffect(() => {
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        const renderer = viewEditor?.renderer;
        if (!!renderer) {
            renderer.setAnimationLoop(animate);
        }
    }, [viewCube])

    const onWindowResize = (offset: number = 0) => {
        const root = document.getElementById(viewportId);
        if (!root) return;
        const aspect = root.offsetWidth / root.offsetHeight;
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        if (viewEditor == null) return;
        if (!viewEditor.camera) return;
        if (viewEditor.camera.type === 'OrthographicCamera') {
            let camera = viewEditor.camera as OrthographicCamera;


            camera.left = camera.bottom * aspect;
            camera.right = camera.top * aspect;
            camera.updateProjectionMatrix();

        } else if (viewEditor.camera.type === 'PerspectiveCamera') {
            let camera = viewEditor.camera as PerspectiveCamera;
            camera.aspect = aspect;
            camera.updateProjectionMatrix();
        }
        let renderer = viewEditor.renderer;
        renderer.setSize(root.offsetWidth - offset, root.offsetHeight);
        renderer.setViewport(0, 0, root.offsetWidth - offset, root.offsetHeight)
        viewCube?.current?.updateSize();
        render();
    }

    const onViewCubeWorking = (value) => {
        if (!value) {
            const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
            const domElement = viewEditor.renderer?.domElement;
            domElement?.focus();
        }
    }

    const createRenderer = () => {
        const root = document.getElementById(viewportId);
        if (!root) return null;
        root.oncontextmenu = (e) => {
            e.preventDefault();
        }
        const renderer = new WebGLRenderer({
            antialias: true,
            logarithmicDepthBuffer: true
        });
        renderer.setPixelRatio(window.devicePixelRatio);
        renderer.setSize(root.offsetWidth, root.offsetHeight);
        // renderer.setClearColor('#f5f5f5', 1.0);
        renderer.setClearColor('#f5f5f5', 0);
        renderer.domElement.style.background = 'linear-gradient(180deg, #002152 1.48%, rgba(189, 224, 234, 1) 40.19%)';
        renderer.autoClear = false;
        renderer.setAnimationLoop(animate);
        root.appendChild(renderer.domElement);
        renderer.domElement.setAttribute('tabindex', '1');
        renderer.domElement.style.outline = '0px';
        return renderer;
    }

    const reSetToolBar = () => {
        setActiveTool(0)
    }

    const animate = () => {
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        if (viewEditor == null) return;

        let delta = _clock.getDelta();
        let needsUpdate = false;
        if (viewCube?.current && viewCube.current?.animating) {
            viewCube?.current.update(delta);
            needsUpdate = true;
        }
        if (needsUpdate) {
            render();
        }
        if (stats?.current) {
            stats.current.update();
        }
    }

    const createCamera = (s = 30, cameraType = CAMERA_TYPE.Orthographic) => {
        const root = document.getElementById(viewportId);
        if (!root) return null;
        const aspect = root.offsetWidth / root.offsetHeight;
        if (cameraType === CAMERA_TYPE.Orthographic) {
            const camera = new OrthographicCamera(-s * aspect, s * aspect, s, -s, 0.1, 1e7);
            camera.position.set(0, 0, 1e7 / 2)
            camera.lookAt(0, 0, 0)
            return camera;
        } else if (cameraType === CAMERA_TYPE.Perspective) {
            const fov = 45;
            const camera = new PerspectiveCamera(fov, aspect, 0.1, 1e6);
            camera.position.set(0, 0, 500)
            camera.lookAt(0, 0, 0)
            return camera;
        }
        return null;
    }

    const initControls = (editor: ViewEditor, renderer: WebGLRenderer, camera: Camera) => {
        editor.camera = camera;
        editor.renderer = renderer;
        let viewControl = editor.initControl();
        return viewControl;
    }

    const createViewCube = (camera: Camera, viewControl: DefaultMouseControl) => {
        let offsetX = 1000000;
        let viewCube = new ViewCube(camera, viewHelperId, graphicStore.extraContext.listeners, offsetX);
        viewCube.controls = viewControl;
        viewCube.listeners = graphicStore.extraContext.listeners;
        return viewCube;
    }

    const render = (arg: string = null) => {
        const viewEditor = graphicStore.extraContext.getViewEditor(viewportId);
        if (!viewEditor) {
            return;
        }
        let renderer = viewEditor.renderer;
        let camera = viewEditor.camera;
        if (!renderer) return;
        const root = document.getElementById(viewportId);
        if (!root) return;
        renderer.setViewport(0, 0, root.offsetWidth, root.offsetHeight)
        renderer.clear();
        if (arg === 'redraw') {
            viewEditor.renderControl.render();
        } else if (arg === 'position') {
            viewEditor.renderControl.render();
        } else if (arg === 'color') {
            viewEditor.renderControl.render();
        }
        if (!graphicStore.extraContext.sceneContext.scene) return;
        renderer.render(graphicStore.extraContext.sceneContext.scene, camera);
        if (!!viewCube?.current) {
            viewCube?.current.render(renderer);
        }
    }


    return (
        <div id={viewportId} className="viewport">
            <div id={viewHelperId} className="view-helper" >
                <div id={viewHelperId + '_top'} className="top arrow">
                    <CaretDownFilled className="arrow-icon" />
                </div>
                <div id={viewHelperId + '_left'} className="left arrow">
                    <CaretRightFilled className="arrow-icon" />
                </div>
                <div id={viewHelperId + '_down'} className="down arrow">
                    <CaretUpFilled className="arrow-icon" />
                </div>
                <div id={viewHelperId + '_right'} className="right arrow">
                    <CaretLeftFilled className="arrow-icon" />
                </div>
            </div>
            <div className="toolbar">
                <div className='tool-btn' onClick={() => {
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('ZA');
                }}>
                    <WorldSvg />
                </div>
                <div className='tool-btn' onClick={() => {
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('P');
                }}>
                    <PickSvg />
                </div>
                <div className='tool-btn' onClick={() => {
                    graphicStore.extraContext.listeners.signals.onOpeCommandActive.dispatch('3DO');
                }}>
                    <RotateSvg />
                </div>
            </div>
        </div>
    )
}