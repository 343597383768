export const GLOBAL_NAMESPACE = 'tncet_bim.';
export const AUTHORIZATION = GLOBAL_NAMESPACE + 'authorization';
export const REMEMBER_USERNAME = GLOBAL_NAMESPACE + 'username';
export const REMEMBER_PASSWORD = GLOBAL_NAMESPACE + 'password';
export const REMEMBER_TAG = GLOBAL_NAMESPACE + 'remember';
export const PROJECT_UUID = GLOBAL_NAMESPACE + 'project_uuid';
export const PROJECT_NAME = GLOBAL_NAMESPACE + 'project_name';
export const MODEL_UUID = GLOBAL_NAMESPACE + 'model_uuid';
export const BRANCH_UUID = GLOBAL_NAMESPACE + 'branch_uuid';
export const BRANCH_LEVEL = GLOBAL_NAMESPACE + 'branch_level';
export const USER_UUID = GLOBAL_NAMESPACE + 'user_uuid';
export const USER_NAME = GLOBAL_NAMESPACE + 'user_name';
export const USER_META = GLOBAL_NAMESPACE + 'user_meta';
export const USER_MOBILE = GLOBAL_NAMESPACE + 'user_mobile';
export const AVATAR = GLOBAL_NAMESPACE + 'avatar';
export const AUTHORITY = GLOBAL_NAMESPACE + 'authority';
export const SELECTED_PANEL = GLOBAL_NAMESPACE + 'selected_panel';
export const SELECTED_MAJOR = GLOBAL_NAMESPACE + 'selected_major';
export const FILTER = GLOBAL_NAMESPACE + 'FILTER';
export const TABBAR_CLOSABLE = `${GLOBAL_NAMESPACE}_tabbar_closable`;
export const ACTIVE_MODEL_UUID = GLOBAL_NAMESPACE + "ACTIVE_MODEL_UUID"
export const ACTIVE_MODEL_NAME = GLOBAL_NAMESPACE + "ACTIVE_MODEL_NAME"
export const SHOW_MODEL_UUIDS = GLOBAL_NAMESPACE + "SHOW_MODEL_UUIDS"

class LocalStore {

    public get(key: string) {
        return JSON.parse(localStorage.getItem(key) || 'null') || null;
    }

    public set(key: string, value: any) {
        localStorage.setItem(key, JSON.stringify(value));
    }

    public remove(key: string): void {
        localStorage.removeItem(key);
    }

    get token(): string {
        return this.get(AUTHORIZATION) || '';
    }

    set token(value: string) {
        this.set(AUTHORIZATION, value);
    }

    get projectUuid(): string {
        return this.get(PROJECT_UUID) || '';
    }

    set projectUuid(value: string) {
        this.set(PROJECT_UUID, value);
    }

    get branchId(): string {
        return this.get(BRANCH_UUID) || '';
    }
    set branchId(value: string) {
        this.set(BRANCH_UUID, value);
    }

    get branchLevel(): number {
        return this.get(BRANCH_LEVEL) || '';
    }
    set branchLevel(value: number) {
        this.set(BRANCH_LEVEL, value);
    }

    get modelUuid(): string {
        return this.get(MODEL_UUID) || '';
    }
    set modelUuid(value: string) {
        this.set(MODEL_UUID, value);
    }

    get userId(): string {
        return this.get(USER_UUID) || '';
    }
    set userId(value: string) {
        this.set(USER_UUID, value);
    }

    get name(): string {
        return this.get(USER_NAME) || '';
    }
    set name(value: string) {
        this.set(USER_NAME, value);
    }

    get mobile(): string {
        return this.get(USER_MOBILE) || '';
    }
    set mobile(value: string) {
        this.set(USER_MOBILE, value);
    }

    get password(): string {
        return this.get(REMEMBER_PASSWORD) || '';
    }
    set password(value: string) {
        this.set(REMEMBER_PASSWORD, value);
    }

    get selectedPanel(): string {
        return this.get(SELECTED_PANEL) || '';
    }

    set selectedPanel(value: string) {
        this.set(SELECTED_PANEL, value);
    }

    get selectedMajor(): string {
        return this.get(SELECTED_MAJOR) || '';
    }

    set selectedMajor(value: string) {
        this.set(SELECTED_MAJOR, value);
    }

    get activeModelUuid(): string {
        return this.get(ACTIVE_MODEL_UUID) || '';
    }

    set activeModelUuid(value) {
        this.set(ACTIVE_MODEL_UUID, value)
    }

    get activeModelName(): string {
        return this.get(ACTIVE_MODEL_NAME) || '';
    }

    set activeModelName(value) {
        this.set(ACTIVE_MODEL_NAME, value)
    }

    set showModelUuidList(value: string[]) {
        this.set(SHOW_MODEL_UUIDS, value)
    }

    get showModelUuidList(): string[] {
        return this.get(SHOW_MODEL_UUIDS) || []
    }

    public getUserId() {
        return this.get(USER_UUID);
    }

    public getUserName() {
        return this.get(USER_NAME);
    }

    public setprojectUuid(value: string) {
        if (value == null) {
            this.remove(PROJECT_UUID);
            return;
        }
        this.set(PROJECT_UUID, value);
    }

    public getprojectUuid() {
        return this.get(PROJECT_UUID);
    }

    public setProjectName(value: string) {
        if (value == null) {
            this.remove(PROJECT_NAME);
            return;
        }
        this.set(PROJECT_NAME, value);
    }

    public getProjectName() {
        return this.get(PROJECT_NAME);
    }

    public setAuthority(types: number[]) {
        if (types == null) {
            this.remove(AUTHORITY);
            return;
        }
        this.set(AUTHORITY, types);
    }

    public getAuthority() {
        return this.get(AUTHORITY);
    }

    public setSelectedPanel(value: string) {
        if (value == null) {
            this.remove(SELECTED_PANEL);
            return;
        }
        this.set(SELECTED_PANEL, value);
    }

    public getSelectedPanel() {
        return this.get(SELECTED_PANEL);
    }

    public setSelectedMajor(value: string) {
        if (value == null) {
            this.remove(SELECTED_MAJOR);
            return;
        }
        this.set(SELECTED_MAJOR, value);
    }

    public getSelectedMajor() {
        return this.get(SELECTED_MAJOR);
    }

    public getUser() {
        return this.get(USER_META);
    }

    public setFilter(value: { [key: number]: string[] } = {}) {
        if (value == null) {
            this.remove(FILTER);
            return;
        }
        this.set(FILTER, value);
    }

    public getFilter() {
        return this.get(FILTER);
    }

    clearProjectInfo() {
        this.remove(AUTHORITY);
        this.remove(PROJECT_UUID);
        this.remove(PROJECT_NAME);
        this.remove(BRANCH_UUID);
        this.remove(BRANCH_LEVEL);
        this.remove(MODEL_UUID);
        this.remove(FILTER);
        this.remove(ACTIVE_MODEL_UUID);
        this.remove(ACTIVE_MODEL_NAME);
        this.remove(SHOW_MODEL_UUIDS);
    }

    clear() {
        this.remove(AUTHORIZATION);
        this.remove(PROJECT_UUID);
        this.remove(PROJECT_NAME);
        this.remove(BRANCH_UUID);
        this.remove(BRANCH_LEVEL);
        this.remove(MODEL_UUID);
        this.remove(USER_UUID);
        this.remove(USER_NAME);
        this.remove(USER_META);
        this.remove(AUTHORITY);
        this.remove(SELECTED_PANEL);
        this.remove(SELECTED_MAJOR);
        this.remove(FILTER);
        this.remove(ACTIVE_MODEL_UUID);
        this.remove(ACTIVE_MODEL_NAME);
        this.remove(SHOW_MODEL_UUIDS);
    }
}

export default new LocalStore();