import React, { useState } from 'react'
import './index.less'
import SpaceRangePanel from './space-range'
import RoamPathPanel from './roam-path'
import classNames from 'classnames'

const SideType = {
    SpaceRange: 'SpaceRange',
    RoamPath: 'RoamPath',
    RoamDetail: 'RoamDetail',
}


export default function LeftSidePanel() {

    const [activeSide, setActiveSide] = useState(SideType.SpaceRange)
    return (
        <div className='left-side-container'>
            <div className='top-tab'>
                <div className={classNames({
                    'tab-item': true,
                    'active': activeSide === SideType.SpaceRange
                })} onClick={() => setActiveSide(SideType.SpaceRange)}>
                    区域
                </div>
                <div className={classNames({
                    'tab-item': true,
                    'active': activeSide === SideType.RoamPath
                })} onClick={() => setActiveSide(SideType.RoamPath)}>
                    路线
                </div>
            </div>
            {activeSide == SideType.SpaceRange && (
                <SpaceRangePanel/>
            )}
            {activeSide == SideType.RoamPath && (
                <RoamPathPanel/>
            )}
        </div>
    )
}
