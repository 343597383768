import { SBeamT } from "./SBeamT";
import { SColumnT } from "./SColumnT";
import { SBarT } from "./SBarT";
import { SBraceT } from "./SBraceT";
import { SWallT } from "./SWallT";
import { SFloorT } from "./SFloorT";
import { SGridPointT } from "./SGridPointT";

export const ModelsInStructure = {
    "SBeamT": SBeamT, // 结构梁
    "SColumnT": SColumnT, // 结构柱
    "SBarT": SBarT, // 结构杆
    "SBraceT": SBraceT, // 结构支撑
    "SWallT": SWallT,   // 结构墙
    "SFloorT": SFloorT,   // 结构板
    "SGridPointT": SGridPointT,   // 结构节点
}