import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export const TextureUploadUrl = '/api-bim/texture/upload';

export function getTextures(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/textures`, {
        params: {
            projectUuid: projectUuid,
            ...params,
        }
    })
}
export function deleteTexture(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/textures/${uuid}`, {
        params: {
            ...params,
        }
    })
}


export function getTextureUrl(urlDetail: string) {
    const urlSuffix = process.env.NODE_ENV === 'development' ? 'http://localhost:9858/api-bim/' : 'https://bim.tncet.com/api-bim/';  //TODO线上部署可能会改动
    return urlSuffix + urlDetail;
}