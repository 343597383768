import { RoamRoute } from "@/commons/interface/building/roam-route";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function findRoamRoutesByBuilding(projectUuid: string, buildingUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/buildings/${buildingUuid}/roam-routes`, {
        params: {
            ...params
        }
    })
}

export function updateRoamRoutes(uuid: string, data: RoamRoute, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/roam-routes/${uuid}`, data, {
        params: {
            ...params
        }
    })
}