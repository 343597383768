import { Building } from "@/commons/interface/project/building";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function createBuilding(building: Building, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/buildings`, building, {
        params: {
            ...params,
        }
    })
}

export function findBuildings(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/buildings`, {
        params: {
            ...params,
        }
    })
}

export function findBuildingsWithModelsByProjectUuid(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/buildings/models`, {
        params: {
            ...params,
        }
    })
}

export function updateBuilding(uuid: string, building: Building, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/buildings/${uuid}`, building, {
        params: {
            ...params,
        }
    })
}

export function deleteBuilding(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/buildings/${uuid}`, {
        params: {
            ...params,
        }
    })
}