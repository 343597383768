
// 动画-图元本身改变

import { graphicStore } from "@/commons/store/graphic-store";
import { update, Tween } from "@tweenjs/tween.js";
// import { ModelBase } from "pythagoras";
import { CatmullRomCurve3, MathUtils, OrthographicCamera, PerspectiveCamera, Vector3 } from "three";

// 按照既定路线漫游
export function roamByFixedRoute(curve: CatmullRomCurve3, transformTime: number, onCompleteCallback, cameraHeight: number = 100) {
    let pathProgress = 0;
    const editor = graphicStore.extraContext.getCurrentViewEditor();
    return new Tween({ progress: pathProgress })
        .to({ progress: 1 }, transformTime) // progress 从 0 移动到 1
        .onUpdate(({ progress }) => {
            pathProgress = progress;
            // 获取路径上的插值点
            const point = curve.getPointAt(progress);
            point.setZ(point.z + cameraHeight);
            // 获取路径上的切线方向，并设置相机朝向
            const tangent = curve.getTangentAt(progress).normalize();
            const position = editor.camera.position;
            // 设置相机坐标
            editor.camera.position.copy(point);
            // 设置视角的方向
            let renderContext = graphicStore.context.renderContext;
            let offset = position.clone().sub(renderContext.viewTarget);
            renderContext.viewTarget.set(tangent.x, tangent.y, tangent.z);
            position.copy(renderContext.viewTarget).add(offset);
            // 设置相机lookat
            let lookat = point.clone().add(tangent);
            editor.camera.lookAt(lookat);
            editor.camera.updateMatrixWorld();
            (editor.camera as (OrthographicCamera | PerspectiveCamera)).updateProjectionMatrix();
            editor.listeners.signals.needRender.dispatch();
        })
        .onComplete(() => {
            onCompleteCallback();
        })
        .start();
}

// 移动相机位置
export const roamCameraMove = (point, roamRoute) => {
    let editor = graphicStore.extraContext.getCurrentViewEditor();
    let entities = roamRoute?.entities || [];
    let pointIndex = entities.findIndex(p => p.uuid === point.uuid);
    // 切换关键点
    editor?.camera?.position?.set(point.position.x, point.position.y, point.position.z);
    if (entities.length != 0) {
        // 找到当前点在entities的位置
        let lookAtPoint;
        if (pointIndex === entities.length - 1) {
            // 当前点是最后一个点，那么就看向前一个点
            lookAtPoint = entities[pointIndex - 1];
        } else {
            // 当前点不是最后一个点，那么就看向后一个点
            lookAtPoint = entities[pointIndex + 1];
        }
        if (!!lookAtPoint) {
            const target = new Vector3(lookAtPoint.position.x, lookAtPoint.position.y, lookAtPoint.position.z);
            editor?.camera?.lookAt(target);
        }
    }
    editor.listeners.signals.needRender.dispatch();
}

/**
 * 
 * @param angleInDegrees 角度
 * @param isVertical 是否水平旋转
 */
export const roamCameraRotate = (angleInDegrees, isVertical: boolean) => {
    let editor = graphicStore.extraContext.getCurrentViewEditor();
    const camera = editor.camera;
    const angleInRadians = MathUtils.degToRad(angleInDegrees);
    let normal;
    if (!isVertical) {
        normal = new Vector3(1, 0, 0);  // 绕 x 轴旋转
    } else {
        normal = new Vector3(0, 1, 0);  // 绕 y 轴旋转
    }
    camera.rotateOnAxis(normal, angleInRadians);
    editor.listeners.signals.needRender.dispatch();
}

// 漫游移动
export const roamMove = (transformTime: number, allPoints: Vector3[], startIndex: number,
    endIndex: number, onCompleteCallback?, cameraHeight: number = 100) => {
    let isReverse = false;
    if (startIndex > endIndex) {
        isReverse = true;
        let tempIndex = endIndex;
        endIndex = startIndex;
        startIndex = tempIndex;
    }
    const points = allPoints.slice(startIndex, endIndex);
    if (isReverse) {
        points.reverse();
    }
    const curve = new CatmullRomCurve3(points);
    let tween = roamByFixedRoute(curve, transformTime, () => {
        if (onCompleteCallback) {
            onCompleteCallback()
        }
    }, cameraHeight)
    roamAnimate();
    return tween;
}

// 激活漫游动画
export const roamAnimate = () => {
    requestAnimationFrame(roamAnimate);
    update();
}