import { ExtrudeSolidT, ModelUtils } from "pytha";

/**
 * 结构柱
 */

export class SBarT extends ExtrudeSolidT {
    type = 'SBarT';
    materialUuid: string;
    sectionUuid: string;

    setMaterialUuid(materialUuid: string) {
        this.materialUuid = materialUuid;
    }

    setSectionUuid(sectionUuid: string) {
        this.sectionUuid = sectionUuid;
    }

    clone() {
        const entity = new SBarT().copy(this);
        entity.uuid = ModelUtils.generateUUID();
        return entity;
    }

    toJson() {
        let parentJson = super.toJson();
        let data = {
            ...parentJson,
            type: this.type,
            materialUuid: this.materialUuid,
            sectionUuid: this.sectionUuid,
        }

        return data;
    }

    fromJson(json) {
        super.fromJson(json);
        return this;
    }



}