import localStore from "@/commons/store/local-store";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function saveUcs(data: any, projectUuid: string, modelUuid: string, branchUuid?: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/ucs`, data, {
        params: {
            ...params,
            modelUuid: modelUuid,
            branchUuid: branchUuid,
        }
    })

}


export function findUcsInProject(projectUuid: string, modelUuid: string, branchUuid?: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/ucs`, {
        params: {
            ...params,
            modelUuid: modelUuid,
            branchUuid: branchUuid,
        }
    })
}

export function deleteUcs(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/ucs/${uuid}`, {
        params: {
            ...params,
        }
    })
}

// TODO
export function updateUcs(data: any, params?: {}): Promise<AxiosResponse<any>> {
    const modelUuid = localStore.modelUuid
    return bimAxios.put(`/models/${modelUuid}/ucs`, data, {
        params: {
            ...params,
        }
    })
}

export function findUcsByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/ucs/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function findGlobalUcs(projectUuid: string, modelUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/models/${modelUuid}/global-ucs`, {
        params: {
            ...params,
        }
    })
}

export function deleteUcsMulti(uuids: string[], params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/ucs/delete-multi`, uuids, {
        params: {
            ...params,
        }
    })
}
