import localStore from '@/commons/store/local-store';
import axios, { AxiosResponse } from 'axios';

export interface StdResponse<T = any> extends AxiosResponse<T> {
    message: string
}

// 状态码定义
// const codeMessage = {
//     200: 'ok',
//     400: '请求数据错误',
//     401: '用户没有权限',
//     404: '请求的资源不存在',
//     500: '服务器异常',
//     503: '服务器过载或维护',
//     504: '网关超时',
// }

const bimAxios = axios.create({
    baseURL: '/api-bim',
    timeout: 60000,
});

bimAxios.interceptors.request.use(
    config => {
        // 从本地Cookie获取token
        // TODO 此处没有登录，暂时使用userId作为token
        // let token = localStore.token || '';
        // let token = cookieStore.getToken() || '';
        // config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)
bimAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        if (error.status === 401) {
            // 未授权登录
            localStore.clear();
        }
        return Promise.reject(error);
    }
)

const feedbackAxios = axios.create({
    baseURL: '/api-feedback',
    timeout: 60000,
})

feedbackAxios.interceptors.request.use(
    config => {
        // 从本地Cookie获取token
        // let token = cookieStore.getToken() || '';
        // config.headers.token = token;
        return config;
    },
    err => {
        console.log(err);
    }
)
feedbackAxios.interceptors.response.use(
    response => {
        if (response.headers['content-type']?.indexOf('application/json') >= 0) {
            return response.data;
        }
        return response;
    },
    (err) => {
        const error = err.response;
        if (!error) {
            return Promise.reject("发生其他异常");
        }
        console.log(err);

        if (error.statue === 401) {
            // 未授权登录
            // appStore.clear();
            // store.userStore.setIsLogin(false);
        }
        return Promise.reject(error);
    }
)


export { bimAxios, feedbackAxios };
