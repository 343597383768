import React, { useContext, useEffect, useState } from 'react'
import ProjectHeader from './header'
import './index.less'
import DrawPanel from '@/panel/draw'
import LeftSidePanel from '@/panel/left-side';
import { useNavigate, useParams } from 'react-router-dom';
import { findBuildings } from '@/api/project/building';
import { Building } from '@/commons/interface/project/building';
import { findModelsByBuildingUuid, findSiteModel, getModelDetail } from '@/api/project/model';
import { Model } from '@/commons/interface/project/model';
import { ModelType } from '@/commons/enums/model-file-type';
import { GlobalProjectUuid, SystemContext } from '@/App';
import { SystemStatus } from '@/commons/context/system-store';

export default function ProjectLayout() {
    // 项目编号
    const { projectUuid, buildingUuid } = useParams();
    // 侧边栏显隐
    const [leftSideVisible, setLeftSideVisible] = useState(true);
    // 专业模型的激活类型
    const [activeModelType, setActiveModelType] = useState(2);

    // 楼栋
    const [buildings, setbuildings] = useState<Building[]>([]);

    const [models, setModels] = useState<Model[]>([]);

    const navigate = useNavigate();
    const systemContext = useContext<SystemStatus>(SystemContext);

    useEffect(() => {
        findBuildings(projectUuid).then(res => {
            let buildings: Building[] = res.data || [];
            let siteBuilding: Building = {
                name: '场地',
                uuid: 'site',
                projectUuid: projectUuid,
                isSite: true,
            }
            buildings.unshift(siteBuilding);
            setbuildings(buildings);
            if (buildings.length > 0 && buildingUuid == null) {
                navigate(`/projects/${projectUuid}/buildings/${buildings[0].uuid}`);
            }
        })
    }, [])

    useEffect(() => {
        if (activeModelType == null) {
            systemContext.setShowModelUuidListValue([])
            systemContext.setActiveModelUuidValue(null)
            return
        }
        let model = models?.find(item => item.type == activeModelType)
        if (!model) {
            systemContext.setShowModelUuidListValue([])
            systemContext.setActiveModelUuidValue(null)
            return
        }
        let showList = [model.uuid]
        if (activeModelType == ModelType.WATER) {
            let buildingModel = models.find(item => item.type == ModelType.ARCHITECTURE)
            if (buildingModel) {
                // systemContext.setShowModelUuidListValue([buildingModel.uuid])
                // todo 将同时要消失的model加入showlist
                showList.push(buildingModel.uuid)
            }
        }
        systemContext.setShowModelUuidListValue(showList)
        systemContext.setActiveModelUuidValue(model.uuid)

    }, [activeModelType])

    // 如果楼栋激活
    useEffect(() => {
        if (buildingUuid == null) return;
        if (buildingUuid === 'site') {
            // 场地
            findSiteModel(projectUuid).then(res => {
                let model: Model = res.data;
                if (model != null) {
                    setModels([model]);
                    systemContext.setActiveModelUuidValue(model.uuid)
                    systemContext.setShowModelUuidListValue([model.uuid])
                }
            })
        } else {
            // 建筑、结构……
            findModelsByBuildingUuid(buildingUuid).then(res => {
                let models: Model[] = res.data || [];
                setModels(models)
                let activeModel = models.find(item => item.type == ModelType.ARCHITECTURE)
                systemContext.setActiveModelUuidValue(activeModel.uuid)
                systemContext.setShowModelUuidListValue([activeModel.uuid])
            })
        }
    }, [buildingUuid])

    const changeBuilding = (value: string) => {
        navigate(`/projects/${projectUuid}/buildings/${value}`);
    }

    return (
        <div className='project-layout-container'>
            <ProjectHeader
                toggleSideShow={() => setLeftSideVisible(!leftSideVisible)}
                activeModelType={activeModelType}
                toggleActiveModel={(type) => setActiveModelType(type)}
                buildings={buildings}
                activeBuildingUuid={buildingUuid || ''}
                changeBuilding={(value) => changeBuilding(value)}
            />
            <div className='main'>
                {leftSideVisible && (
                    <LeftSidePanel />
                )}
                {buildingUuid != null && (
                    <div style={{
                        position: "absolute",
                        left: "0",
                        right: "0",
                        height: "100%",
                        width: "100%"
                    }}>
                        <DrawPanel />
                    </div>
                )}
            </div>

        </div >
    )
}
