import { BoxT, ModelUtils } from "pytha";

/**
 * 结构柱
 */

export class SWallT extends BoxT {
    type = 'SWallT';
    materialUuid: string;

    setMaterialUuid(materialUuid: string) {
        this.materialUuid = materialUuid;
    }

    clone() {
        const entity = new SWallT().copy(this);
        entity.uuid = ModelUtils.generateUUID();
        return entity;
    }

    toJson() {
        let parentJson = super.toJson();
        let data = {
            ...parentJson,
            type: this.type,
            materialUuid: this.materialUuid
        }

        return data;
    }

    fromJson(json) {
        super.fromJson(json);
        return this;
    }



}