import React from 'react';
import logo from './logo.svg';
import './App.less';
import { Navigate, Route, Routes } from 'react-router-dom';
import ProjectLayout from './layout';
import { SystemStatus, useSystemService } from './commons/context/system-store';

// 默认激活的Uuid
export const GlobalProjectUuid = '6ad6b2f7-d5db-4bf3-afbd-7a80e9f02646';

export const SystemContext = React.createContext<SystemStatus>(null);
function App() {
    const systemContext = useSystemService();
    return (
        <SystemContext.Provider value={systemContext}>
            <div className="tn-base">
                <Routes>
                    <Route path='/projects/:projectUuid/buildings/:buildingUuid' element={<ProjectLayout />} />
                    <Route path='/projects/:projectUuid/*' element={<ProjectLayout />} />
                    <Route path="*" element={<Navigate to={`/projects/${GlobalProjectUuid}`} />} />
                </Routes>
            </div>
        </SystemContext.Provider>

    );
}

export default App;
