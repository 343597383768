import React, { act, useContext, useEffect, useRef, useState } from 'react'
import "./roam-path.less";
import { ReactComponent as RoamSvg } from '@/commons/icons/roam/roam.svg'
import { ReactComponent as TitleRoamSvg } from '@/commons/icons/roam/title-roam.svg'
import { ReactComponent as PlaySvg } from '@/commons/icons/roam/play.svg'
import { ReactComponent as PauseSvg } from '@/commons/icons/roam/pause.svg'
import { ReactComponent as BackSvg } from '@/commons/icons/roam/back.svg'
import { ReactComponent as DeleteSvg } from '@/commons/icons/roam/delete.svg'
import { ReactComponent as TitleSelectOpenSvg } from '@/commons/icons/roam/title-select-open.svg'
import { RoamRoute } from '@/commons/interface/building/roam-route';
import { useParams } from 'react-router-dom';
import { findRoamRoutesByBuilding, updateRoamRoutes } from '@/api/building/roam-route';
import { message } from 'antd';
import classNames from 'classnames';
import { CatmullRomCurve3, Vector3 } from 'three';
import { roamAnimate, roamByFixedRoute, roamCameraRotate, roamMove } from './tween';
import { SystemContext } from '@/App';
import { CAMERA_TYPE } from '../draw/viewport';
import { ContextMenuFC } from 'tncet-common';
import { getGroupByUuids } from '@/api/spec-common/group';
import { Group } from '@/commons/interface/speccommon/group';
import { removeAll } from '@tweenjs/tween.js'
import { graphicStore } from '@/commons/store/graphic-store';

//漫游时间 TODO
const RoamTime = 100000;
const CameraHeight = 1500;
const RoamPointSplit = 1000;
const RoamSpeed = 100; // 移动速度
const RoamRotationSpeed = 15; // 旋转速度

interface RoamGroup extends Group {
    pointUuid: string;
}

export default function RoamPathPanel() {
    const { projectUuid, buildingUuid } = useParams();

    const systemContext = useContext(SystemContext)

    const [roamRoutes, setRoamRoutes] = useState<RoamRoute[]>([]);
    const [loading, setLoading] = useState<boolean>(false);
    // 当前正在激活漫游的路线
    const [roamingRoute, setRoamingRoute] = useState<RoamRoute>(null);
    const roumingRouteRef = useRef(null);
    const [isPause, setIsPause] = useState(null);
    // 激活详情的漫游路线
    const [activeRoute, setActiveRoute] = useState<RoamRoute>(null);
    const activeRouteRef = useRef(null);
    const [deletingGroup, setDeletingGroup] = useState<RoamGroup>(null);
    // 漫游所需的变量
    const roamingPointsRef = useRef([]);    // 漫游曲线分割后的所有点
    const roamingIndexRef = useRef(0);   // 当前在漫游曲线中的索引
    const roamingWholeCurveRef = useRef(null);   // 完整漫游曲线
    const roamingTweenRef = useRef(null);

    // 关键点的组列表
    const [groups, setGroups] = useState<RoamGroup[]>([]);
    // 下拉选择漫游路线框是否课件
    const [selectRoamRouteVisible, setSelectRoamRouteVisible] = useState<boolean>(false);
    const [selectRoamRouteLeft, setSelectRoamRouteLeft] = useState<number>(0);
    const [selectRoamRouteTop, setSelectRoamRouteTop] = useState<number>(0);

    const titleTextRef = useRef(null);

    useEffect(() => {
        roumingRouteRef.current = roamingRoute;
    }, [roamingRoute])

    useEffect(() => {
        return () => {
            if (!!roamingTweenRef.current) {
                removeAll()
            }
        }
    }, [])

    useEffect(() => {
        activeRouteRef.current = activeRoute;
        const groupUuids = [];
        if (!!activeRoute) {
            // 遍历点，获取关键点，找到组名
            for (let p of activeRoute.entities) {
                if (p.isKeyPoint == null || !p.isKeyPoint || p.group == null
                    || Object.keys(p.group).length === 0 || p.group[7] == null
                    || p.group[7][0] == null) continue;
                groupUuids.push(p.group[7][0]);
            }
        }
        // 遍历获取组详情
        if (groupUuids.length === 0) {
            setGroups([]);
            return;
        }
        setLoading(true);
        const roamGroups: RoamGroup[] = [];
        getGroupByUuids(projectUuid, groupUuids).then((res) => {
            let data = res.data || [];
            let groupMap = {};
            data.forEach(g => {
                groupMap[g?.uuid] = g;
            });
            for (let p of activeRoute.entities) {
                if (p.isKeyPoint == null || !p.isKeyPoint || p.group == null
                    || Object.keys(p.group).length === 0 || p.group[7] == null
                    || p.group[7][0] == null) continue;
                const group = groupMap[p.group[7][0]];
                if (group == null) continue;
                roamGroups.push({
                    ...group,
                    pointUuid: p.uuid
                })
            }
        }).catch(err => {
            message.error("获取组详情失败");
        }).finally(() => {
            setGroups(roamGroups);
            setLoading(false);
        })
    }, [activeRoute])

    useEffect(() => {
        document.addEventListener('keydown', onKeydown);
        return () => {
            document.removeEventListener('keydown', onKeydown);
        }
    }, [])

    const onKeydown = (e) => {
        e.preventDefault();
        e.stopPropagation();
        console.log(e.key);
        // if (!activeRouteRef.current) return;
        if (e.key === 'w' || e.key === 's') {
            if (!roamingPointsRef.current) return;
            let endIndex;
            if (e.key === 'w') {
                endIndex = roamingIndexRef.current + RoamSpeed;
                endIndex = Math.min(endIndex, roamingPointsRef.current.length - 1);
            } else {
                endIndex = roamingIndexRef.current - RoamSpeed
                endIndex = Math.max(0, endIndex);
            }
            if (endIndex === roamingIndexRef.current) return;
            // if (roamingTweenRef.current != null) {
            //     removeAll();
            //     roamingTweenRef.current = null;
            // }
            roamMove(RoamTime / 10, roamingPointsRef.current, roamingIndexRef.current, endIndex, () => {
                roamingIndexRef.current = endIndex;
            }, CameraHeight)
        } else if (e.key === 'ArrowUp' || e.key === 'ArrowDown'
            || e.key === 'ArrowLeft' || e.key === 'ArrowRight') {
            let vertical;
            let angle;
            if (e.key === 'ArrowUp' || e.key === 'ArrowDown') {
                vertical = false;
                angle = e.key === 'ArrowUp' ? RoamRotationSpeed : -RoamRotationSpeed;
            } else {
                vertical = true;
                angle = e.key === 'ArrowLeft' ? RoamRotationSpeed : -RoamRotationSpeed;
            }
            roamCameraRotate(angle, vertical);
        }
    }

    useEffect(() => {
        if (!projectUuid || !buildingUuid) return;
        generateData();
    }, [projectUuid, buildingUuid])

    useEffect(() => {
        if (systemContext.currentCameraType === CAMERA_TYPE.Orthographic) {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Perspective);
            // graphicStore.extraContext.getCurrentViewEditor().viewCube.prepareAnimationData('pbcgf');
            // graphicStore.extraContext.getCurrentViewEditor().viewCube.animating = true;
        }
        return () => {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Orthographic);
        }
    }, [])

    // 获取路由线路
    const generateData = () => {
        setLoading(true);

        findRoamRoutesByBuilding(projectUuid, buildingUuid)
            .then(res => {
                setRoamRoutes(res.data || []);
            }).catch(err => {
                message.error("获取数据失败");
            }).finally(() => {
                setLoading(false);
            })
    }

    // 激活路线
    const activeRoamPath = (roam: RoamRoute, needActive: boolean = false) => {
        if (loading) {
            message.warning("请等待数据加载完成");
            return;
        }
        if (activeRouteRef.current?.uuid == roam.uuid) return;
        if (systemContext.currentCameraType !== CAMERA_TYPE.Perspective) {
            systemContext.setCurrentCameraType(CAMERA_TYPE.Perspective);
        }
        if (needActive) {
            setActiveRoute(roam);
        } else {
            setActiveRoute(null);
        }
        let points = [];
        roam.entities?.forEach(p => points.push(new Vector3(p.position.x, p.position.y, p.position.z)));
        roamingWholeCurveRef.current = new CatmullRomCurve3(points);
        roamingIndexRef.current = 0;
        // 将曲线分割成等分点
        roamingPointsRef.current = roamingWholeCurveRef.current.getPoints(RoamPointSplit);
    }

    // 开始漫游路线
    const roamSelectPath = (e, roam: RoamRoute) => {
        e.preventDefault();
        e.stopPropagation();
        activeRoamPath(roam);
        setRoamingRoute(roam);
        setIsPause(false);
        if (roamingTweenRef.current != null) {
            removeAll();
            roamingTweenRef.current = null;
        }
        roamingTweenRef.current = roamByFixedRoute(roamingWholeCurveRef.current, RoamTime, () => { setIsPause(null) }, CameraHeight);
        roamAnimate();
    }

    // 删除关键点
    const deleteGroup = (group: RoamGroup) => {
        if (group == null || group.pointUuid == null || group.pointUuid === '') {
            message.warning("要删除的组信息有误");
            return;
        }
        // TODO 删除弹窗
        setDeletingGroup(group);
    }

    // 删除关键点
    const updateKeyPoint = () => {
        const pointIndex = activeRoute.entities
            .findIndex(p => p.uuid === deletingGroup.pointUuid);
        activeRoute.entities[pointIndex] = {
            ...activeRoute.entities[pointIndex],
            isKeyPoint: false,
            groupIds: [],
        }
        setLoading(true);
        updateRoamRoutes(activeRoute.uuid, activeRoute).then(res => {
            if (res.status === 200) {
                message.success("删除组成功");
                const roamIndex = roamRoutes.findIndex(r => r.uuid === activeRoute.uuid);
                roamRoutes[roamIndex] = activeRoute;
                setRoamRoutes([...roamRoutes]);
                setGroups([...groups.filter(g => g.uuid !== deletingGroup.uuid)]);
                return;
            }
            message.error("删除组失败")
        }).catch(err => {
            message.error("删除组失败");
        }).finally(() => {
            setLoading(false);
            setDeletingGroup(null);
        })
    }

    // 漫游到关键点
    const roam2Point = (group: RoamGroup) => {
        const point = activeRoute.entities.find(p => p.uuid === group.pointUuid);
        if (!point) {
            message.warning("关键点不存在");
            return;
        }
        let closeDistance = Number.MAX_VALUE;
        const pointPos = new Vector3(point.position.x, point.position.y, point.position.z);
        let closeIndex = 0; // 最接近point的索引
        for (let index = 0; index < roamingPointsRef.current.length; index++) {
            const splitPoint = roamingPointsRef.current[index];
            const splitDistance = splitPoint.distanceTo(pointPos);
            if (splitDistance < closeDistance) {
                closeDistance = splitDistance;
                closeIndex = index;
            }
        }
        if (closeIndex === roamingIndexRef.current) return;
        if (roamingTweenRef.current != null) {
            removeAll();
            roamingTweenRef.current = null;
        }
        roamMove(RoamTime, roamingPointsRef.current, roamingIndexRef.current, closeIndex,
            () => roamingIndexRef.current = closeIndex, CameraHeight);
    }

    return (
        <div className='roam-path-panel'>
            {<div className='roam-path-list'>
                {roamRoutes.map(roam => {
                    return <div className={classNames({
                        'roam-path-item': true,
                        'active': activeRoute?.uuid === roam.uuid || roamingRoute?.uuid === roam.uuid
                    })} key={roam.uuid} onClick={() => activeRoamPath(roam, true)}>
                        <div className='name ellipsis'>{roam.name}</div>
                        {(roamingRoute?.uuid !== roam.uuid || roamingRoute?.uuid === roam.uuid && isPause == null) && <div className='ope-icon' onClick={(e) => roamSelectPath(e, roam)}><RoamSvg /></div>}
                        {roamingRoute?.uuid === roam.uuid && isPause != null && isPause == true &&
                            <div className='ope-icon' onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsPause(false);
                                if (roamingTweenRef.current != null) {
                                    roamingTweenRef.current.resume();
                                }
                            }}><PauseSvg /></div>}
                        {roamingRoute?.uuid === roam.uuid && isPause != null && isPause == false &&
                            <div className='ope-icon' onClick={(e) => {
                                e.stopPropagation();
                                e.preventDefault();
                                setIsPause(true);
                                if (roamingTweenRef.current != null) {
                                    roamingTweenRef.current.pause();
                                }
                            }}><PlaySvg /></div>}
                    </div>
                })}
            </div>}
            {!!activeRoute && false && <div className='roam-path-detail'>
                <div className='title'>
                    <div className='title-back-svg' onClick={() => setActiveRoute(null)} ><BackSvg /></div>
                    <div className='title-text' onClick={(e) => {
                        e.stopPropagation();
                        e.preventDefault();
                        if (titleTextRef.current) {
                            const rect = titleTextRef.current.getBoundingClientRect();
                            setSelectRoamRouteTop(rect.bottom + 8);
                            setSelectRoamRouteLeft(rect.left - 8);
                        }
                        setSelectRoamRouteVisible(true);
                    }}>
                        <div className='text ellipsis' ref={titleTextRef}>{activeRoute?.name}</div>
                        <div className='title-select-svg'>
                            <TitleSelectOpenSvg />
                        </div>
                    </div>
                    {/* <div className='title-roam-svg'><TitleRoamSvg /></div> */}
                    {isPause == null && <div className='title-roam-svg' onClick={(e) => {
                        roamSelectPath(e, activeRoute);
                    }}><TitleRoamSvg /></div>}
                    {isPause != null && isPause == true && <div className='title-roam-svg' onClick={() => {
                        setIsPause(false);
                        if (roamingTweenRef.current != null) {
                            roamingTweenRef.current.pause();
                        }
                    }}><PlaySvg /></div>}
                    {isPause != null && isPause == false && <div className='title-roam-svg' onClick={() => {
                        setIsPause(true);
                        if (roamingTweenRef.current != null) {
                            roamingTweenRef.current.resume();
                        };
                    }}><PauseSvg /></div>}
                </div>
                <div className='group-list'>
                    {groups.map((group, index) => {
                        return (
                            <div className='group-item' key={group.uuid}>
                                <div className='group-index'>{index + 1}</div>
                                <div className='group-detail'>{group?.name}</div>
                                <div className='group-opes'>
                                    <div className='group-ope'>
                                        <RoamSvg onClick={() => roam2Point(group)} />
                                    </div>
                                    {/* <div className='group-ope'>
                                        <DeleteSvg onClick={() => deleteGroup(group)} />
                                    </div> */}
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>}
            <div className='roam-tip'>
                <img src={require('./png/roam-ope-tip.png')} />
            </div>

            <ContextMenuFC visible={selectRoamRouteVisible} left={selectRoamRouteLeft} top={selectRoamRouteTop}
                width={148}
                onCancel={() => setSelectRoamRouteVisible(false)} wrapperClassname='roam-path-select-menu'>
                {roamRoutes.map(roamRoute => {
                    return <div key={roamRoute.uuid} className='roam-path-select-menu-item'
                        onClick={() => {
                            setSelectRoamRouteVisible(false);
                            if (roamRoute?.uuid === activeRoute?.uuid) return;
                            setActiveRoute(roamRoute);
                        }}>
                        {roamRoute.name}
                    </div>
                })}
            </ContextMenuFC>
            {/* <div className='roam-path-ope'>
                <div className='svg'>+</div>
                <div className='text'>新建线路</div>
            </div> */}
        </div>
    )
}
