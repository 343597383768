import React, { useEffect } from 'react'
import './header.less'
import {
    MenuOutlined
} from '@ant-design/icons'
import { ReactComponent as LogoSvg } from './icons/logo.svg';
import { ReactComponent as UploadSvg } from './icons/upload.svg';
import { ReactComponent as InspectSvg } from './icons/inspect.svg';
import { ReactComponent as StatsSvg } from './icons/stats.svg';
import { ReactComponent as TaskSvg } from './icons/task.svg';
import { ReactComponent as NoticeSvg } from './icons/notice.svg';
import classNames from 'classnames';
import { Building } from '@/commons/interface/project/building';
import { Select } from 'antd';
import { MODEL_TYPE_LIST } from '@/commons/enums/model-file-type';
import { useParams } from 'react-router';

interface Iprops {
    toggleSideShow: () => void
    activeModelType: number
    toggleActiveModel: (type: number) => void
    buildings?: Building[]
    activeBuildingUuid?: string
    changeBuilding: (buildingUuid: string) => void
}

export default function ProjectHeader(props: Iprops) {

    const { buildingUuid } = useParams()

    // let btnArr = [
    //     {name: '建筑', type: 1},
    //     {name: '结构', type: 2},
    //     {name: '给排水', type: 3},
    //     {name: '空调', type: 4},
    //     {name: '电气', type: 5},
    //     {name: '消防', type: 6},
    // ]

    useEffect(() => {
        if (props.buildings != null) {

        }
    }, [props.buildings])
    return (
        <div className='project-header-container'>
            <div className='left-side-icon' onClick={() => {
                props.toggleSideShow?.();
            }}>
                <MenuOutlined className='icon' />
            </div>
            <div className='logo'>
                <div className='logo-font'>
                    <LogoSvg />
                </div>
            </div>

            <div className='building'>
                <div className='indicator'>·</div>
                <Select className='building-select' popupMatchSelectWidth={false} value={props.activeBuildingUuid} onChange={(value) => props.changeBuilding?.(value)}>
                    {props.buildings?.map((building, idx) => (
                        <Select.Option key={building.uuid} value={building.uuid}>{building.name}</Select.Option>
                    ))}
                </Select>
            </div>
            <div className='model-major-group'>
                {MODEL_TYPE_LIST.map(btn => (
                    <div className={classNames({
                        'model-major-btn': true,
                        'active': props.activeModelType === btn.value,
                        'disable': buildingUuid == "site"
                    })} onClick={() => {
                        if (buildingUuid == "site") return
                        props.toggleActiveModel?.(btn.value)
                    }}>
                        {btn.label}
                    </div>
                ))}
            </div>
            <span className='fill-remaining-space'></span>
            <div className='right-icon-group'>
                <div className='icon-btn'>
                    <UploadSvg />
                </div>
                <div className='icon-btn'>
                    <InspectSvg />
                </div>
                <div className='icon-btn'>
                    <StatsSvg />
                </div>
                <div className='icon-btn'>
                    <TaskSvg />
                </div>
                <div className='icon-btn'>
                    <NoticeSvg />
                </div>
            </div>
        </div>
    )
}
