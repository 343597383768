import { CaptureTagType, ICapturePoint, ModelUtils, Point, PointT } from "pytha";
import { Vector3 } from "three";


/**
 * 轴网节点
 */
export class SGridPointT extends PointT {
    type = 'SGridPointT';

    isGrid?: boolean = false;
    gridNodeNumber?: number;
    isGridManual?: boolean = false;

    constructor(position = new Point(), uuid = null) {
        super(position, uuid);
    }

    getGeoProperties() {
        let properties = [];
        let position = this.context.ucsContext.trans2UcsCoord(this.position);
        properties.push({ title: '位置X坐标', key: 'positionX', value: Number(position.x.toFixed(4)), modifiable: !!!this.isGrid });
        properties.push({ title: '位置Y坐标', key: 'positionY', value: Number(position.y.toFixed(4)), modifiable: !!!this.isGrid });
        properties.push({ title: '位置Z坐标', key: 'positionZ', value: Number(position.z.toFixed(4)), modifiable: !!!this.isGrid });
        return properties;
    }


    clone(rename: boolean = false) {
        let point = new SGridPointT().copy(this);
        point.uuid = ModelUtils.generateUUID();
        return point;
    }

    copy(point: SGridPointT) {
        this.set(point.position);
        this.color = point.color;
        this.selectedPoints = [];
        this.layerUuid = point.layerUuid;
        if (point.isSelected) {
            this.selectedPoints = point.selectedPoints;
        }
        this.layerUuid = point.layerUuid;
        this.isColorByLayer = point.isColorByLayer;
        this.isGrid = point.isGrid;
        // this.entityType = point.entityType;
        this.extraData = point.extraData ? {...point.extraData} : null;
        this.group = point?.group ? {...point?.group} : {};
        return this;
    }

    getCapturePoints(type: number, targetPoint: Vector3): ICapturePoint[] {
        if (!!this.hidden) return [];
        if (this.isGrid) {
            //如果是节点
            if (type == CaptureTagType.NODE_POINT) {
                return [{ point: this.position, type: CaptureTagType.NODE_POINT }]
            } else {
                return []
            }
        } else {
            //如果不是节点
            if (type == CaptureTagType.END_POINT) {
                return [{ point: this.position, type: CaptureTagType.END_POINT }]
            } else {
                return []
            }
        }
    }


    toJson = () => {
        return {
            uuid: this.uuid,
            color: `#${this.color.getHexString()}`,
            type: this.type,
            isColorByLayer: this.isColorByLayer,
            graphicUuid: this.extraContext.getCurrentViewEditor().graphicId,
            layerUuid: this.layerUuid,
            layerName: `${this.context.layerContext.getLayerName(this.layerUuid)}`,
            position: {
                x: this.position.x,
                y: this.position.y,
                z: this.position.z,
            },
            // groupIds: this.groupIds,
            // entityType: this.entityType,
            isGrid: this.isGrid,
            gridNodeNumber: this.gridNodeNumber,
            isGridManual: this.isGridManual,
            extraData: this.extraData,
            group: this.group,
        }
    }

    fromJson = (json) => {
        this.uuid = json.uuid;
        this.graphicUuid = json.graphicUuid;
        this.isColorByLayer = json.isColorByLayer;
        this.color.set(json.color);
        this.layerUuid = json.layerUuid;
        this.position.set(json.position.x, json.position.y, json.position.z);
        // this.id = json.uuid;
        this.type = json.type;
        // this.groupIds = json?.groupIds;
        // this.entityType = json?.entityType;
        this.isGrid = json?.isGrid;
        this.gridNodeNumber = json?.gridNodeNumber;
        this.isGridManual = json?.isGridManual;
        this.extraData = json?.extraData;
        this.group = json?.group ? json?.group : {};
        return this;
    }
}