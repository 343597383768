import { Model } from "@/commons/interface/project/model";
import { StdResponse, bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function createModel(model: Model, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/models`, model, {
        params: {
            ...params,
        }
    })
}

export function findModels(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/models`, {
        params: {
            ...params,
        }
    })
}
export function getModelDetail(modelUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/models/${modelUuid}`, {
        params: {
            ...params,
        }
    })
}
export function findSiteModel(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/models/site`, {
        params: {
            ...params,
        }
    })
}
export function findModelsByBuildingUuid(buildingUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/buildings/${buildingUuid}/models`, {
        params: {
            ...params,
        }
    })
}

export function updateModel(uuid: string, model: Model, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/models/${uuid}`, model, {
        params: {
            ...params,
        }
    })
}

export function deleteModel(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/models/${uuid}`, {
        params: {
            ...params,
        }
    })
}