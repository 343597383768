export const ModelType = {
    SITE: 1,
    ARCHITECTURE: 2,
    STRUCTURE: 3,
    HVAC: 4,
    WATER: 5,
    ELECTRICITY: 6,
    FIRE: 7,
}

export const MODEL_TYPE_LIST = [
    // { value: 1, label: "场地" },
    { value: 2, label: "建筑" },
    { value: 3, label: "结构" },
    { value: 4, label: "暖通" },
    { value: 5, label: "给排水" },
    { value: 6, label: "电气" },
    { value: 7, label: "消防" },
]

export function getModelTypeName(value: number) {
    return MODEL_TYPE_LIST.find(item => item.value === value)
}