import { StdResponse, bimAxios } from "@/config/axios";

export function saveLayer(data: any, projectUuid: string, modelUuid: string, branchUuid?: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/layers`, data, {
        params: {
            ...params,
            modelUuid: modelUuid,
            branchUuid: branchUuid,
        }
    })
}

export function findLayersInProjectUuid(projectUuid: string, modelUuid: string, branchUuid?: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/layers`, {
        params: {
            ...params,
            modelUuid: modelUuid,
            branchUuid: branchUuid,
        }
    })
}

export function findAllLayersInProjectUuid(projectUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/all-layers`, {
        params: {
            ...params,
        }
    })
}


export function deleteLayer(layerUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.delete(`/layers/${layerUuid}`, {
        params: {
            ...params,
        }
    })
}
export function updateLayer(data: any, projectUuid: string, params?: {}): Promise<StdResponse<any>> {
    return bimAxios.put(`/projects/${projectUuid}/layers`, data, {
        params: {
            ...params,
        }
    })
}
