import { useCallback, useState } from "react";
import ReconnectingWebSocket from "reconnecting-websocket";
import { RenderMode } from "pytha";
import { Component } from "../interface/speccommon/component";
import localStore from "../store/local-store";
import { CAMERA_TYPE } from "@/panel/draw/viewport";

export interface SystemStatus {

    rws: ReconnectingWebSocket
    resetRws: (value: ReconnectingWebSocket) => void

    updateDrawPanel: boolean
    setUpdateDrawPanel: (value: boolean) => void

    currentRenderMode: number
    setCurrentRenderMode: (value: number) => void

    currentCameraType: number
    setCurrentCameraType: (value: number) => void

    selectedComponentType: string
    setSelectedComponentType: (value: string) => void

    componentCount: number
    setComponentCount: (value: number) => void

    cacheComponent: Component
    setCacheComponent: (value: Component) => void

    refreshComponentFlag: string
    setRefreshComponentFlag: (value: string) => void

    cacheComName: string
    setCacheComName: (value: string) => void

    cacheComExplain: string
    setCacheComExplain: (value: string) => void

    editingComponent: boolean
    setEditingComponent: (value: boolean) => void

    activeModelUuid: string,
    setActiveModelUuidValue: (value: string) => void

    activeModelName: string,
    setActiveModelNameValue: (value: string) => void

    showModelUuidList: string[],
    setShowModelUuidListValue: (value: string[]) => void

    updateModel: string,
    setUpdateModel: (value: string) => void

    currentProjectUuid: string
    setCurrentProjectUuid: (value: string) => void

    currentModelUuid: string
    setCurrentModelUuid: (value: string) => void

    currentBuildingUuid: string
    setCurrentBuildingUuid: (value: string) => void

    currentBranchUuid: string
    setCurrentBranchUuid: (value: string) => void
}

export function useSystemService() {

    const [rws, setRws] = useState<ReconnectingWebSocket>(null); // WebSocket
    const [updateDrawPanel, setUpdateDrawPanel] = useState<boolean>(); // 重新初始化绘图区
    const [currentRenderMode, setCurrentRenderMode] = useState<number>(RenderMode.WIREFRAME); // 当前渲染类型（0-线框 1-概念）

    const [currentCameraType, setCurrentCameraType] = useState<number>(CAMERA_TYPE.Orthographic);  //当前相机类型

    const [selectedComponentType, setSelectedComponentType] = useState<string>('');  //选中的组件类型
    const [componentCount, setComponentCount] = useState<number>(0);  //组件个数
    const [cacheComponent, setCacheComponent] = useState<Component>({});  //临时组件，创建组件时记录信息用
    const [cacheComName, setCacheComName] = useState<string>('');
    const [cacheComExplain, setCacheComExplain] = useState<string>('');
    const [refreshComponentFlag, setRefreshComponentFlag] = useState<string>('');  //刷新组件侧边栏

    const [editingComponent, setEditingComponent] = useState<boolean>(false); // 是否正在编辑组件
    const [activeModelUuid, setActiveModelUuid] = useState<string>() // 激活的model
    const [activeModelName, setActiveModelName] = useState<string>() // 激活的model的名称
    const [showModelUuidList, setShowModelUuidList] = useState<string[]>([]) // 可以展示的model
    const [updateModel, setUpdateModel] = useState<string>('') // 重新刷新model

    const [currentProjectUuid, setCurrentProjectUuid] = useState<string>('') // 当前项目uuid
    const [currentModelUuid, setCurrentModelUuid] = useState<string>('') // 当前模型uuid
    const [currentBuildingUuid, setCurrentBuildingUuid] = useState<string>('') // 当前楼栋uuid
    const [currentBranchUuid, setCurrentBranchUuid] = useState<string>('') // 当前分支uuid

    const resetRws = useCallback((value: ReconnectingWebSocket) => {
        if (rws != null) {
            rws.close();
        }
        setRws(value);
    }, [])

    const setActiveModelUuidValue = (value: string) => {
        setActiveModelUuid(value)
        localStore.activeModelUuid = value;
    }

    const setActiveModelNameValue = (value: string) => {
        setActiveModelName(value)
        localStore.activeModelName = value;
    }

    const setShowModelUuidListValue = (value: string[]) => {
        setShowModelUuidList([...value])
        localStore.showModelUuidList = value
    }


    return {
        rws, resetRws,
        updateDrawPanel, setUpdateDrawPanel,
        currentRenderMode, setCurrentRenderMode,
        currentCameraType, setCurrentCameraType,
        selectedComponentType, setSelectedComponentType,
        componentCount, setComponentCount,
        cacheComponent, setCacheComponent,
        refreshComponentFlag, setRefreshComponentFlag,
        cacheComName, setCacheComName,
        cacheComExplain, setCacheComExplain,
        editingComponent, setEditingComponent,
        activeModelUuid, setActiveModelUuidValue,
        activeModelName, setActiveModelNameValue,
        showModelUuidList, setShowModelUuidListValue,
        updateModel, setUpdateModel,
        currentProjectUuid, setCurrentProjectUuid,
        currentModelUuid, setCurrentModelUuid,
        currentBuildingUuid, setCurrentBuildingUuid,
        currentBranchUuid, setCurrentBranchUuid,
    } as SystemStatus
}

