import { SelectStrategy, SelectionBox, ViewEditor, Listeners, TnEngineExtraContext, TnEngineContext, ModelBase, RenderMode, SelectionRect } from "pytha";
import { Vector2, Matrix4, Vector3, TypedArray } from "three";
import { TINY_SELECT_STRATEGY_TYPE } from "./select-type";


const MOUSE = {
    LEFT: 0,
    MIDDLE: 1,
    RIGHT: 2,
}


/**
 * 相交选择策略，遍历图元，通过选择框与图元的求交，判断图元是否被选中
 */
export class OnlySelectStrategy extends SelectStrategy {

    strategyId: number = TINY_SELECT_STRATEGY_TYPE.ONLY_SELECT;

    private _pickBox: SelectionBox;
    private _pickRect: SelectionRect;

    private _mouseStart: Vector2 = new Vector2();
    private _mouseMove: Vector2 = new Vector2();

    renderClamp = false;

    constructor(editor: ViewEditor, listeners: Listeners) {
        super(editor, listeners);
        this.extraContext = TnEngineExtraContext.getInstance();
        this.context = TnEngineContext.getInstance();

        let camera = editor.camera;
        this._pickBox = new SelectionBox(camera);
        this._pickRect = new SelectionRect(camera);

        this.editor.renderer.domElement.addEventListener('pointerdown', this.onPointerDown);
        this.listeners.signals.emptySelectedEntities.add(this.resetSelectedEntities);
        this.listeners.signals.onProcessEscape.add(this.onProcessEscape);
        this.listeners.signals.onZoomChanged.add(this.onZoomChanged);
        this.listeners.signals.forceEntitiesSelected.add(this.forceEntitiesSelected);
        this.listeners.signals.onOnlySelectType.add(this.setOnlySelectTypes);
        this.listeners.signals.onOnePickOnly.add(this.setOnePickOnly);
    }


    reset = () => {
        this.enabled = true;
        this.onePickOnly = false;
        this.hoverEnabled = false;
    };

    dispose = () => {
        this.editor.renderer.domElement.removeEventListener('pointerdown', this.onPointerDown);
        this.listeners.signals.emptySelectedEntities.remove(this.resetSelectedEntities);
        this.listeners.signals.onProcessEscape.remove(this.onProcessEscape);
        this.listeners.signals.onZoomChanged.remove(this.onZoomChanged);
        this.listeners.signals.forceEntitiesSelected.remove(this.forceEntitiesSelected);
        this.listeners.signals.onOnlySelectType.remove(this.setOnlySelectTypes);
        this.listeners.signals.onOnePickOnly.remove(this.setOnePickOnly);
        this.selectedEntities = null;
    };

    setOnlySelectTypes = (types: Set<string>) => {
        this.onlySelectTypes = types;
    };

    setOnePickOnly = (status: boolean) => {
        this.onePickOnly = status;
    };

    forceEntitiesSelected = (entities: ModelBase[]) => {
        entities.forEach(entity => {
            entity.isSelected = true;
            this.selectedEntities[entity.uuid] = entity;
        })
        this.listeners.signals.onSelectChanged.dispatch(entities);
    };

    onProcessEscape = (id: number) => {
        if (id != null && id === this.id) return;
        this.resetSelectedEntities();
        this.enabled = true;
        this.onlySelectTypes = new Set<string>();
    };

    onZoomChanged = (scale: number) => {
        // let entities: ModelBase[] = this.getSelectedEntityList();
        // entities.forEach(entity => {
        //     if (!!entity.updateSelectedMaterial) {
        //         entity.updateSelectedMaterial();
        //     }
        // })
    };


    onPointerDown = (event: any) => {
        if (!this.enabled) return;
        if (event.button !== MOUSE.LEFT) return;

        this._mouseStart.set(event.clientX, event.clientY);
        this._mouseMove.set(event.clientX, event.clientY);
        // raycast
        let entity: ModelBase = this.raycastEntity(event);

        let uuid = entity?.uuid || null;
        if (!!uuid) {
            // shoot target
            let selectedEntitieArr = [];
            let entitySelect = this.selectedEntities[uuid];
            if (this.onlySelectTypes && this.onlySelectTypes.size > 0 && !this.onlySelectTypes.has(entity.type)) {
                return;
            }
            if (!!entitySelect) {
                // remove
                delete this.selectedEntities[uuid];
                entity.isSelected = false;
                selectedEntitieArr.push(entity);
            } else if (!entitySelect && !event.shiftKey) {
                // add
                for (let uuid in this.selectedEntities) {
                    let entity_1 = this.selectedEntities[uuid]
                    entity_1.isSelected = false
                    selectedEntitieArr.push(entity_1)
                    delete this.selectedEntities[uuid]
                }
                this.selectedEntities[uuid] = entity;
                entity.isSelected = true;
                selectedEntitieArr.push(entity);
            }
            if (selectedEntitieArr?.length > 0) {
                this.listeners.signals.onSelectChanged.dispatch(selectedEntitieArr);
                this.listeners.signals.needRender.dispatch('redraw');
            }
        }
    };


    pickEntity = (event: any) => {
        return null;
    };

    getSelectedEntityList = () => {
        let res = [];
        for (let key of Object.keys(this.selectedEntities)) {
            let entity = this.selectedEntities[key];
            if (entity == null) continue;
            res.push(entity);
        }
        return res;
    };


    // 点选
    raycastEntity = (event) => {
        let rect = this.editor.renderer.domElement.getBoundingClientRect();
        let x = (event.clientX - rect.x) / rect.width * 2 - 1;
        let y = -(event.clientY - rect.y) / rect.height * 2 + 1;
        let pickEpsilon = this.extraContext.editStatusContext.PickEpsilon;

        let start_x = x - pickEpsilon;
        let start_y = y - pickEpsilon;
        let end_x = x + pickEpsilon;
        let end_y = y + pickEpsilon;

        this._pickBox.update(new Vector2(start_x, start_y), new Vector2(end_x, end_y));
        this._pickRect.update(new Vector2(start_x, start_y), new Vector2(end_x, end_y));

        if (this._pickRect.minX === this._pickRect.maxX && this._pickRect.minY === this._pickRect.maxY) return null;
        let viewMatrix = this.editor.camera.matrixWorldInverse;
        let projectMatrix = this.editor.camera.projectionMatrix;
        let transMat = new Matrix4().identity().premultiply(viewMatrix).premultiply(projectMatrix);
        let filteredEntities: { [key: string]: ModelBase } = {};

        for (let key in this.editor.renderControl.objectRenderBucket) {
            let bucket = this.editor.renderControl.objectRenderBucket[key];
            for (let mapItem of bucket.renderMaps) {
                let position: TypedArray = new Float32Array();
                if (mapItem.buffer?.attributes?.position != null) {
                    position = mapItem.buffer?.attributes?.position.array
                }
                if (position.length <= 0 || mapItem.hidden) continue;
                // let result = this.projectBufferGL(mapItem.positions, transMat.elements);
                let result = this.projectBuffer(position, transMat);

                const itemSize = 2;
                const count = result.length / itemSize;

                if (mapItem.renderType === "Line") {
                    for (let mi = 0; mi < mapItem.models.length; mi++) {
                        let entity = mapItem.models[mi];
                        if (filteredEntities[entity.uuid] != null || entity.unSelectable) {
                            continue;
                        }
                        for (let i = 0; i < entity.renderGeometryArraySize * 2 / 3; i += 4) {
                            let start = i + entity.renderGeometryArrayStart * 2 / 3;

                            this._vector2_1.set(result[start], result[start + 1]);
                            this._vector2_2.set(result[start + 2], result[start + 3]);
                            let selected = this._pickRect.select(this._vector2_1, this._vector2_2);
                            if (selected) {
                                let rootEntity = entity.getRootEntity();
                                filteredEntities[rootEntity.uuid] = rootEntity;
                                break;
                            }
                        }
                    }
                } else if (mapItem.renderType === "Mesh") {
                    for (let mi = 0; mi < mapItem.models.length; mi++) {
                        let entity = mapItem.models[mi];
                        if (entity.unSelectable) {
                            continue;
                        }
                        if (this.context.renderContext.renderType === RenderMode.WIREFRAME) {
                            for (let i = 0; i < entity.renderGeometryArraySize * 2 / 3; i += 4) {
                                let start = i + entity.renderGeometryArrayStart * 2 / 3;

                                this._vector2_1.set(result[start], result[start + 1]);
                                this._vector2_2.set(result[start + 2], result[start + 3]);
                                let selected = this._pickRect.select(this._vector2_1, this._vector2_2);
                                if (selected) {
                                    let rootEntity = entity.getRootEntity();
                                    filteredEntities[rootEntity.uuid] = rootEntity;
                                    break;
                                    // TODO: calc selectedPoints
                                }
                            }
                        } else {
                            for (let i = 0; i < entity.renderGeometryArraySize * 2 / 3; i += 6) {
                                let start = i + entity.renderGeometryArrayStart * 2 / 3;

                                this._vector2_1.set(result[start], result[start + 1]);
                                this._vector2_2.set(result[start + 2], result[start + 3]);
                                this._vector2_3.set(result[start + 4], result[start + 5]);
                                let selected = this._pickRect.select(this._vector2_1, this._vector2_2, this._vector2_3);
                                if (selected) {
                                    let rootEntity = entity.getRootEntity();
                                    filteredEntities[rootEntity.uuid] = rootEntity;
                                    break;
                                    // TODO: calc selectedPoints
                                }
                            }
                        }
                    }
                } else if (mapItem.renderType === "AlwaysMesh") {
                    for (let mi = 0; mi < mapItem.models.length; mi++) {
                        let entity = mapItem.models[mi];
                        if (entity.unSelectable) {
                            continue;
                        }
                        for (let i = 0; i < entity.renderGeometryArraySize * 2 / 3; i += 6) {
                            let start = i + entity.renderGeometryArrayStart * 2 / 3;

                            this._vector2_1.set(result[start], result[start + 1]);
                            this._vector2_2.set(result[start + 2], result[start + 3]);
                            this._vector2_3.set(result[start + 4], result[start + 5]);
                            let selected = this._pickRect.select(this._vector2_1, this._vector2_2, this._vector2_3);
                            if (selected) {
                                let rootEntity = entity.getRootEntity();
                                filteredEntities[rootEntity.uuid] = rootEntity;
                                break;
                                // TODO: calc selectedPoints
                            }
                        }
                    }
                } else if (mapItem.renderType === "TextureMesh") {
                    for (let mi = 0; mi < mapItem.renderItems.length; mi++) {
                        let renderItem = mapItem.renderItems[mi];
                        let uuid = renderItem.model.getRootEntity().uuid;
                        let entity = this.editor.entityDictInViewport[uuid];
                        if (entity == null) continue;
                        if (filteredEntities[entity.uuid] != null || entity.unSelectable) {
                            continue;
                        }
                        if (this.context.renderContext.renderType === RenderMode.WIREFRAME) {
                            for (let i = 0; i < renderItem.size * 2 / 3; i += 4) {
                                let start = i + renderItem.start * 2 / 3;
                                this._vector2_1.set(result[start], result[start + 1]);
                                this._vector2_2.set(result[start + 2], result[start + 3]);

                                let selected = this._pickRect.select(this._vector2_1, this._vector2_2);
                                if (selected) {
                                    let rootEntity = entity.getRootEntity();
                                    filteredEntities[rootEntity.uuid] = rootEntity;
                                    break;
                                }

                            }
                        } else {
                            for (let i = 0; i < renderItem.size * 2 / 3; i += 6) {
                                let start = i + renderItem.start * 2 / 3;
                                this._vector2_1.set(result[start], result[start + 1]);
                                this._vector2_2.set(result[start + 2], result[start + 3]);
                                this._vector2_3.set(result[start + 4], result[start + 5]);

                                let selected = this._pickRect.select(this._vector2_1, this._vector2_2, this._vector2_3);
                                if (selected) {
                                    let rootEntity = entity.getRootEntity();
                                    filteredEntities[rootEntity.uuid] = rootEntity;
                                    break;
                                }

                            }
                        }

                    }
                } else if (mapItem.renderType === "Point") {
                    for (let i = 0; i < count; i++) {
                        let entity = mapItem.models[i];
                        if (filteredEntities[entity.uuid] != null || entity.unSelectable) {
                            continue;
                        }
                        this._vector2_1.set(result[i * itemSize], result[i * itemSize + 1]);
                        let selected = false;
                        selected = this._pickRect.select(this._vector2_1);

                        if (selected) {
                            let rootEntity = entity.getRootEntity();
                            filteredEntities[rootEntity.uuid] = rootEntity;
                        }
                    }

                }
            }

        }

        let selectEntity = this._pickBox.pickSelectEntity(filteredEntities)

        return selectEntity
    }

    private _vector2_1 = new Vector2();
    private _vector2_2 = new Vector2();
    private _vector2_3 = new Vector2();

    resetSelectedEntities = () => {
        let res = []
        for (let key in this.selectedEntities) {
            let entity = this.selectedEntities[key];
            if (!entity) continue;

            entity.isSelected = false;
            res.push(entity);
            delete this.selectedEntities[key];
        }
        if (res.length > 0) {
            this.listeners.signals.onSelectChanged.dispatch(res);
            this.listeners.signals.needRender.dispatch('redraw');
        }
    }

    private _vector: Vector3 = new Vector3();

    projectBuffer = (points: TypedArray, matrix: Matrix4) => {
        const count = points.length / 3;
        let result = [];
        for (let i = 0; i < count; i++) {
            this._vector.set(points[i * 3], points[i * 3 + 1], points[i * 3 + 2]);
            this._vector.applyMatrix4(matrix);
            result.push(this._vector.x, this._vector.y);
        }
        return result;
    }

}