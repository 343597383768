import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";

export function findFontsInGraphic(projectUuid: string, modelUuid: string, branchUuid?: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/fonts`, {
        params: {
            ...params,
            modelUuid: modelUuid,
            branchUuid: branchUuid,
        }
    })
}

export function findFontTtfs(params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/fonts/ttfs`, {
        params: {
            ...params,
        }
    })
}

export function findFontGlyphs(params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/fonts/glyphs`, {
        params: {
            ...params,
        }
    })
}

export function addFont(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/fonts`, data, {
        params: {
            ...params,
        }
    })
}

