import localStore from "@/commons/store/local-store";
import { bimAxios } from "@/config/axios";
import { AxiosResponse } from "axios";
import qs from "qs";


export function createPrimitive(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives`, data, {
        params: {
            ...params,
        }
    })
}

export function createPrimitiveMulti(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives/multi`, data, {
        params: {
            ...params,
        }
    })
}

export function findPrimitivesInGraphic(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives`, {
        params: {
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}
export function findPrimitivesInGraphicByTypes(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives/types`, {
        params: {
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}
export function findPrimitiveUuidsInGraphicByTypes(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives/types/uuid`, {
        params: {
            ...params,
        },
        paramsSerializer: params => {
            return qs.stringify(params, { arrayFormat: 'comma' })
        }
    })
}
export function findPrimitivesByName(projectUuid: string, name: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/primitives/name`, {
        params: {
            ...params,
            name: name,
        }
    })
}
export function findPrimitivesByUuid(uuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/primitives/${uuid}`, {
        params: {
            ...params,
        }
    })
}

export function deletePrimitivesInGraphic(uuids: string[], projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives/delete`, uuids, {
        params: {
            ...params,
            projectUuid: projectUuid,
        }
    })
}
export function updateComponentEntities(data: any, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/primitives/components/multi`, data, {
        params: {
            ...params,
        }
    })
}
// 移动图元到某模型
export function updatePrimitivesModel(primitiveUuids: string[], projectUuid: string, targetmodelUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/primitives/move-model`, primitiveUuids, {
        params: {
            ...params,
            projectUuid: projectUuid,
            modelUuid: targetmodelUuid,
        }
    })
}
// ------------------备份------------------
// 备份项目图元
export function backupPrimitivesByProjectUuid(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.post(`/projects/${projectUuid}/backups`, {
        params: {
            ...params,
        }
    })
}

// 获取项目备份列表
export function getCollectionBackupsByProjectUuid(projectUuid: string, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.get(`/projects/${projectUuid}/backups`, {
        params: {
            ...params,
        }
    })
}

// 载入备份
export function loadBackupByProjectUuidAndTime(projectUuid: string, time: number, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.put(`/projects/${projectUuid}/backups`, null, {
        params: {
            ...params,
            time: time
        }
    })
}

// 删除备份
export function deleteBackupByProjectUuidAndTime(projectUuid: string, time: number, params?: {}): Promise<AxiosResponse<any>> {
    return bimAxios.delete(`/projects/${projectUuid}/backups`, {
        params: {
            ...params,
            time: time
        }
    })
}